import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { PMCTariffDataService } from '../services/pmc-tariff-data-service';
import { AgGridService } from 'common/services/ag-grid-service';
import { UiService } from 'shared/services/ui-service';
import { AccountStore } from 'features/account/stores/account-store';
import history from '../../../../shared/components/header-component/component/history';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { PMCAttachmentResponse, PMCNotesResponse } from '../model/model';
import { PMCTariffMessages } from '../constants/enums';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import moment from 'moment';
import _, { toNumber } from 'lodash';


export class PMCTariffDataStore {
    @observable baseTariffData: any[] = [];
    @observable fileUploadGridData: PMCAttachmentResponse[] = [];
    backupAttachmentGridData: PMCAttachmentResponse[] = [];
    @observable notesGridData: PMCNotesResponse[] = [];
    backupNotesGridData: PMCNotesResponse[] = [];
    @observable createdUser;

    @observable showLoader = false;
    @observable selectedPipelineGroup;
    @observable pipelineGroupDropdownOptions: any[] = [];
    @observable operatorDropdownOptions: any[] = [];
    @observable selectedEffectiveStartDate;
    @observable selectedEffectiveEndDate;
    @observable selectedEffectiveDate;
    @observable selectedTariffRequestType;
    @observable tariffRequestTypeDropdownOptions: any[] = [];
    @observable selectedSystemGroup;
    @observable selectedSystemGroupID;
    @observable isFromTariffScreen = false;
    @observable systemGroupDropdownOptions: any[] = [];
    @observable selectedFacilityCode;
    @observable selectedExternalSystems;
    @observable externalSystemDropdownOptions: any[] = [];
    @observable selectedEscalationMonth = { value: 'Select', label: 'Select' };
    @observable escalationMonthDropdownOptions: any[] = [];
    @observable enteredEscalationAmount;
    @observable selectedLSD;
    @observable enteredOperator;
    @observable selectedDestination;
    @observable selectedProducts;
    @observable productDropdownOptions: any[] = [];
    @observable description;
    @observable selectedDistributionType;
    @observable distributionTypeDropdownOptions: any[] = [];
    @observable selectedProductType;
    @observable productTypeDropdownOptions: any[] = [];
    @observable selectedTariffApplication;
    @observable enteredAgreement = '';
    @observable selectedAgreementEndDate;
    @observable tariffApplicationDropdownOptions: any[] = [];
    @observable selectedUnit;
    @observable unitDropdownOptions: any[]= [];
    @observable selectedCurrency;
    @observable selectedRegulator;
    @observable currencyDropdownOptions: any[] = [];
    @observable regulatorDropdownOptions: any[] = [];
    @observable shrinkageDropdownOptions: any[] = [];
    @observable lossAllowanceDropdownOptions: any[] = [];
    @observable facilityCodeDropdownOptions: any[] = [];
    @observable lsdDropdownOptions: any[] = [];
    @observable destinationDropdownOptions: any[] = [];
    @observable enteredPrimaryRate = 0;
    // @observable isPrimaryRateEditable = false;
    @observable enteredSecondaryRate = 0;
    @observable enteredSurcharge = 0;
    @observable enteredLMCIAbandonmentRate = 0;
    @observable enteredInjectionFee = 0;
    @observable enteredRecieptTerminalingRate = 0;
    @observable enteredQualityMgmtFee = 0;
    @observable isQualityMgmtFeeEditable = false;
    @observable enteredElectronicReportingFee = 0;
    @observable isElectronicReportingFeeEditable = false;
    @observable deliveryTerminallingFee = 0;
    @observable lossAllowance = { value: 'Select', label: 'Select' };
    @observable isLossAllowanceEditable = false;
    @observable shrinkage = { value: 'Select', label: 'Select' };
    @observable isShrinkageEditable = false;
    @observable bsw = 0;
    @observable isBSWEditable = false;
    @observable intraSystemTransferFee = 0;
    @observable isIntraSystemTransferFeeEditable = false;
    @observable lumpSumFees = 0;
    @observable ofDecimal = 2;
    @observable totalFee = 0;
    @observable editableTariffData;
    @observable pmcTariffDetailID;
    @observable pmcTariffNumber;
    @observable tariffStatus;
    @observable assetGroupID;
    @observable pmcTariffFeeDetailID;
    @observable isTariffSaved = false;
    @observable isDuplicateTariff = false;
    @observable isPMCTariffDuplicated = false;
    @observable isTariffNoteAdded = false;
    @observable isTariffNoteSaved = false;
    @observable isTariffEditingEnabled = false;
    @observable isTariffNumberClicked = false;
    @observable isTariffEditingCancelled = false;
    @observable cellValueChangeMap: any = {};
    @observable cellValueChangeMapAttachment: any = {};
    @observable previousLoc;
    @observable isShowEndDateConfirmationModal = false;
    @observable tariffDetailsToSetEndDate: any = {};
    @observable pmcTariffList: any[] = [];

    pipelineGroupData: any[]=[];
    tariffRequestTypeData: any[]=[];
    systemGroupData: any[]=[];
    facilityCodeData: any[] = [];
    unitData: any[] = [];
    currencyData: any[] = [];
    regulatorData: any[] = [];
    lossAllowanceData: any[]=[];
    shrinkageData: any[]=[];
    productsData: any[]=[];
    externalSystemData: any[]=[];
    distributionTypeData: any[]=[];
    productTypeData: any[]=[];
    tariffApplicationData: any[]=[];

    constructor(
        public pmcTariffDataService: PMCTariffDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridServiceForAddAttachment: AgGridService
    ) {
        makeAutoObservable(this);
    }

    @action
    init(): void {
        this.createdUser = this.accountStore.displayName;
        this.getTariffRequestTypeDropdownData();
        if(this.selectedTariffRequestType && !this.isTariffEditingCancelled){
            this.getPipelineGroupDropdownData();
            this.getSystemGroupDropdownData();
            this.getSettingsData();
            this.getProductDropdownData();
            this.getDistributionTypeDropdownData();
            this.getExternalSystemDropdownData();
            this.getProductTypeDropdownData();
            this.getTariffApplicationDropdownData();
            this.getUnitDropdownData();
            this.getCurrencyDropdownData();
            this.getRegulatorDropdownData();
            this.getFacilityCodeDropdownData();
            this.getLSDDropdownData();
            this.getDestinationDropdownData();
            this.getShrinkageDropdownData();
            this.getLossAllowanceDropdownData();
            this.getEscalationMonthDropdownData();
        }
    }

    monthNames = [{ 'label': 'Jan', 'value': 'January' }, { 'label': 'Feb', 'value': 'February' }, { 'label': 'Mar', 'value': 'March' }, { 'label': 'Apr', 'value': 'April' }, { 'label': 'May', 'value': 'May' }, { 'label': 'Jun', 'value': 'June' }, { 'label': 'Jul', 'value': 'July' }, { 'label': 'Aug', 'value': 'August' }, { 'label': 'Sep', 'value': 'September' }, { 'label': 'Oct', 'value': 'October' }, { 'label': 'Nov', 'value': 'November' }, { 'label': 'Dec', 'value': 'December' }];

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Pipeline Region'))
    async getPipelineGroupDropdownData(): Promise<void> {
        const pipelineGroups = await this.pmcTariffDataService.getPipelineGroup();
        this.pipelineGroupData = pipelineGroups;
        this.showLoader = false;
        const a = (pipelineGroups.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedPipelines =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setPipelineGroupDropdownOptions(sortedPipelines);
    }

    @action
    setPipelineGroupDropdownOptions(options){
        this.pipelineGroupDropdownOptions = options
    }

    @action
    setPipelineGroup(pipelineGroup: any): void {
        this.selectedPipelineGroup = pipelineGroup;
    }

    @action
    setEffectiveDate(effectiveDate: Date): void {
        this.selectedEffectiveDate = effectiveDate;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffRequestTypeDropdownData(): Promise<void> {
        const userJurisdiction = this.accountStore.defaultUserLocation;
        const tariffRequestTypes = await this.pmcTariffDataService.getTariffRequestTypes(userJurisdiction);
        this.tariffRequestTypeData = tariffRequestTypes;
        this.showLoader = false;
        const a = (tariffRequestTypes.map(x => {return { 'value': x.assetGroupID, 'label': x.name}}));
        const sortedTariffRequestTypes =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setTariffRequestTypeDropdownOptions(sortedTariffRequestTypes);
    }

    @action
    setTariffRequestTypeDropdownOptions(options){
        this.tariffRequestTypeDropdownOptions = options
    }

    @action
    setTariffRequestType(tariffRequestType: any): void {
        this.selectedTariffRequestType = tariffRequestType;
        this.init();
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Pipeline System'))
    async getSystemGroupDropdownData(): Promise<void> {
        const assetGroupID = this.assetGroupID ? this.assetGroupID : this.tariffRequestTypeData.filter(x=>x.name===this.selectedTariffRequestType.label)[0]?.assetGroupID;
        const systemGroups = await this.pmcTariffDataService.getPMCPipelineSystems(assetGroupID);
        this.systemGroupData = systemGroups;
        this.showLoader = false;
        const a = (systemGroups.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedPipelines =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setSystemGroupDropdownOptions(sortedPipelines);
    }

    @action
    setSystemGroupDropdownOptions(options){
        this.systemGroupDropdownOptions = options;
    }

    @action
    setSystemGroup(systemGroup: any): void {
        this.selectedSystemGroup = systemGroup;
        const systemGroupID = !this.isFromTariffScreen && this.selectedSystemGroupID ? this.selectedSystemGroupID : this.systemGroupData.find(x => x.valueText === systemGroup.label)?.settingID;
        this.selectedSystemGroupID = systemGroupID;
        if(this.selectedEffectiveStartDate !== '' && this.selectedEffectiveStartDate && !this.isTariffEditingCancelled){
            this.getPopulateFees();
        }
    }

    @action
    setEffectiveStartDate(effectiveStartDate: Date): void {
        this.selectedEffectiveStartDate = effectiveStartDate;
        this.setEffectiveDate(effectiveStartDate);
        if(this.selectedSystemGroup !== '' && this.selectedSystemGroup){
            if(!this.isDuplicateTariff){
            this.getPopulateFees();
        }
        }
        this.isTariffNumberClicked = false;
    }

    @action
    setEffectiveEndDate(effectiveEndDate: Date): void {
        const endDate = effectiveEndDate ? new Date(moment(effectiveEndDate).endOf('month').format('YYYY-MM-DDTHH:mm:ss')) : '';
        this.selectedEffectiveEndDate = endDate;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Fees'))
    async getPopulateFees(){
        const startDate = moment(this.selectedEffectiveStartDate).format('YYYY-MM-DD');
        const fees = await this.pmcTariffDataService.getFees(this.selectedSystemGroupID, startDate);
        this.assignPMCTariffFees(fees);
    }

    assignPMCTariffFees(fees){
        const {
                primaryPipelineTariff,
                secondaryRate,
                surcharge,
                lmciAbondmentS_C:lmciAbondment,
                injectionFee,
                receiptTerminalling,
                qualityManagementFee,
                electronicReportingFee,
                deliveryTerminalling,
                lossAllowance,
                shrinkage,
                bsw,
                intraSystemTransferFee,
                lumpSumFees,
                allowedDecimal,
                total
     } = fees;
     this.enteredPrimaryRate = primaryPipelineTariff ? primaryPipelineTariff : 0;
     this.enteredSecondaryRate = secondaryRate ? secondaryRate : 0;
     this.enteredSurcharge = surcharge ? surcharge : 0;
     this.enteredLMCIAbandonmentRate = lmciAbondment ? lmciAbondment : 0;
     this.enteredInjectionFee = injectionFee ? injectionFee : 0;
     this.enteredRecieptTerminalingRate = receiptTerminalling ? receiptTerminalling : 0;
     this.enteredQualityMgmtFee = qualityManagementFee ? qualityManagementFee : 0;
     this.enteredElectronicReportingFee = electronicReportingFee ? electronicReportingFee : 0;
     this.deliveryTerminallingFee = deliveryTerminalling ? deliveryTerminalling : 0;
     const lossAllowanceLabelValue = lossAllowance ? this.lossAllowanceData.find(x=>x.settingID==lossAllowance)?.valueText : null;
     const shrinkageLabelValue = shrinkage ? this.shrinkageData.find(x=>x.settingID==shrinkage)?.valueText : null;
     this.lossAllowance = {label:lossAllowanceLabelValue, value:lossAllowance};
     this.shrinkage = {label:shrinkageLabelValue, value:shrinkage};
     this.bsw = bsw ? bsw : 0;
     this.intraSystemTransferFee = intraSystemTransferFee ? intraSystemTransferFee : 0;
     this.lumpSumFees = lumpSumFees ? lumpSumFees : 0;
     this.ofDecimal = allowedDecimal ? allowedDecimal : 2;
     this.totalFee = total ? total : 0;
    }

    @action
    getEscalationMonthDropdownData(){
        this.setEscalationMonthDropdownOptions(this.monthNames);
    }

    @action
    setEscalationMonthDropdownOptions(options){
        this.escalationMonthDropdownOptions = options;
    }

    @action
    setEscalationMonth(escalationMonth: any): void {
        this.selectedEscalationMonth = escalationMonth;
    }

    @action
    setEscalationAmount(escalationAmount: string): void {
        this.enteredEscalationAmount = escalationAmount;
    }

    @action
    setDescription(description: string): void {
        this.description = description;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getSettingsData(): Promise<void> {
        const userJurisdiction = this.accountStore.defaultUserLocation;
        const response = await this.pmcTariffDataService.getMiscellaneousSettingsData(userJurisdiction);
        const filteredResponse = response.filter((item) => item.settingName === 'PMC Operator' && item.action != 'D');
        const a = filteredResponse.map(x => { return { 'value': x.valueText, 'label': x.valueText } });
        this.operatorDropdownOptions = a;
    }

    @action
    setOperator(operator: any): void {
        this.enteredOperator = operator;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Products'))
    async getProductDropdownData(): Promise<void> {
        const products = await this.pmcTariffDataService.getProducts();
        this.productsData = products;
        this.showLoader = false;
        const a = (products.map(x => {return { 'settingID': x.settingID, 'valueText': x.valueText}}));
        const sortedProducts =  _.orderBy(a, [x=>x.valueText], ['asc']);
        this.setProductDropdownOptions(sortedProducts);
    }

    @action
    setProductDropdownOptions(options){
        this.productDropdownOptions = options
    }

    @action
    setProducts(products: any[]): void {
        this.selectedProducts = products;
    }

    getSelectedProductIDs(){
        const productIDs = this.selectedProducts.map((product) =>{
            return this.productsData.find(x=>x.valueText===product)?.settingID;
        })
        return productIDs.toString();
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Distribution Types'))
    async getDistributionTypeDropdownData(): Promise<void> {
        const distributionTypes = await this.pmcTariffDataService.getDistributionTypes();
        this.distributionTypeData = distributionTypes;
        this.showLoader = false;
        const a = (distributionTypes.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedDistributionType =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setDistributionTypeDropdownOptions(sortedDistributionType);
    }

    @action
    setDistributionTypeDropdownOptions(options){
        this.distributionTypeDropdownOptions = options
    }

    @action
    setDistributionType(distributionType: any): void {
        this.selectedDistributionType = distributionType;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching External Systems'))
    async getExternalSystemDropdownData(): Promise<void> {
        const externalSystems = await this.pmcTariffDataService.getExternalSystems();
        this.externalSystemData = externalSystems;
        this.showLoader = false;
        const a = (externalSystems.map(x => {return { 'settingID': x.settingID, 'valueText': x.valueText}}));
        const sortedExternalSystems =  _.orderBy(a, [x=>x.valueText], ['asc']);
        this.setExternalSystemDropdownOptions(sortedExternalSystems);
    }

    @action
    setExternalSystemDropdownOptions(options){
        this.externalSystemDropdownOptions = options;
    }

    @action
    setExternalSystems(externalSystems: any[]): void {
        this.selectedExternalSystems = externalSystems;
    }

    getSelectedExternalSystems(){
        const externalSystems = this.selectedExternalSystems.map((externalSystem) =>{
            return this.externalSystemData.find(x=>x.valueText===externalSystem)?.settingID;
        })
        return externalSystems.toString();
    }

    getSelectedExternalSystemIDs(){
        if(this.selectedExternalSystems && this.selectedExternalSystems.length > 0) {
            const externalSystemIDs = this.selectedExternalSystems.map((externalSystem) =>{
                return this.externalSystemData.find(x=>x.valueText===externalSystem)?.settingID;
            })
            return externalSystemIDs.toString();
        }
       return ''; 
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Product Types'))
    async getProductTypeDropdownData(): Promise<void> {
        const productTypes = await this.pmcTariffDataService.getProductTypes();
        this.productTypeData = productTypes;
        this.showLoader = false;
        const a = (productTypes.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedProductTypes =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setProductTypeDropdownOptions(sortedProductTypes);
    }

    @action
    setProductTypeDropdownOptions(options){
        this.productTypeDropdownOptions = options
    }

    @action
    setProductType(productType: any): void {
        this.selectedProductType = productType;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Tariff Application'))
    async getTariffApplicationDropdownData(): Promise<void> {
        const tariffApplications = await this.pmcTariffDataService.getTariffApplications();
        this.tariffApplicationData = tariffApplications;
        this.showLoader = false;
        const a = (tariffApplications.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedTariffApplications =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setTariffApplicationDropdownOptions(sortedTariffApplications);
    }

    @action
    setTariffApplicationDropdownOptions(options){
        this.tariffApplicationDropdownOptions = options
    }

    @action
    setTariffApplication(tariffApplication: any): void {
        this.selectedTariffApplication = tariffApplication;
    }

    @action
    setAgreement(agreement: string): void {
        this.enteredAgreement = agreement;
    }

    @action
    setAgreementEndDate(agreementEndDate: Date): void {
        this.selectedAgreementEndDate = agreementEndDate;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching UOM values'))
    async getUnitDropdownData(): Promise<void> {
        const units = await this.pmcTariffDataService.getUnits();
        this.unitData = units;
        this.showLoader = false;
        const a = (units.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedValues =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setUnitDropdownOptions(sortedValues);
    }

    @action
    setUnitDropdownOptions(options){
        this.unitDropdownOptions = options
    }

    @action
    setUnit(unit: any): void {
        this.selectedUnit = unit;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Regulator values'))
    async getRegulatorDropdownData(): Promise<void> {
        const regulatores = await this.pmcTariffDataService.getRegulator();
        this.regulatorData = regulatores;
        this.showLoader = false;
        const a = (regulatores.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedValues =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setRegulatorDropdownOptions(sortedValues);
    }

    @action
    setRegulatorDropdownOptions(options){
        this.regulatorDropdownOptions = options
    }

    @action
    setRegulator(regulator: any): void {
        this.selectedRegulator = regulator;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Currency values'))
    async getCurrencyDropdownData(): Promise<void> {
        const currencies = await this.pmcTariffDataService.getCurrency();
        this.currencyData = currencies;
        this.showLoader = false;
        const a = (currencies.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedValues =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setCurrencyDropdownOptions(sortedValues);
    }

    @action
    setCurrencyDropdownOptions(options){
        this.currencyDropdownOptions = options
    }

    @action
    setCurrency(currency: any): void {
        this.selectedCurrency = currency;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Facility code values'))
    async getFacilityCodeDropdownData(): Promise<void> {
        const facilityCodes = await this.pmcTariffDataService.getFacilityCodes();
        this.facilityCodeData = facilityCodes;
        this.showLoader = false;
        const a = (facilityCodes.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedValues =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setFacilityCodeDropdownOptions(sortedValues);
    }

    @action
    setFacilityCodeDropdownOptions(options){
        this.facilityCodeDropdownOptions = options
    }

    @action
    setFacilityCode(facilityCode: any): void {
        this.selectedFacilityCode = facilityCode;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching LSD values'))
    async getLSDDropdownData(): Promise<void> {
        const lsdValues = await this.pmcTariffDataService.getLSD();
        this.showLoader = false;
        const a = (lsdValues.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedValues =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setLSDDropdownOptions(sortedValues);
    }

    @action
    setLSDDropdownOptions(options){
        this.lsdDropdownOptions = options
    }

    @action
    setLSD(lsd: any): void {
        this.selectedLSD = lsd;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Destination values'))
    async getDestinationDropdownData(): Promise<void> {
        const destinations = await this.pmcTariffDataService.getDestination();
        this.showLoader = false;
        const a = (destinations.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedDestinations =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setDestinationDropdownOptions(sortedDestinations);
    }

    @action
    setDestinationDropdownOptions(options){
        this.destinationDropdownOptions = options
    }

    @action
    setDestination(destination: any): void {
        this.selectedDestination = destination;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Shrinkage values'))
    async getShrinkageDropdownData(): Promise<void> {
        const shrinkageValues = await this.pmcTariffDataService.getPMCShrinkage();
        this.shrinkageData = shrinkageValues;
        this.showLoader = false;
        const a = (shrinkageValues.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedShrinkage =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setShrinkageDropdownOptions(sortedShrinkage);
    }

    @action
    setShrinkageDropdownOptions(options){
        this.shrinkageDropdownOptions = options
    }

    @action
    setShrinkage(shrinkage: any): void {
        this.shrinkage = shrinkage;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Loss Allowance values'))
    async getLossAllowanceDropdownData(): Promise<void> {
        const lossAllowanceValues = await this.pmcTariffDataService.getPMCLossAllwance();
        this.lossAllowanceData = lossAllowanceValues;
        this.showLoader = false;
        const a = (lossAllowanceValues.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedLossAllowance =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setLossAllowanceDropdownOptions(sortedLossAllowance);
    }

    @action
    setLossAllowanceDropdownOptions(options){
        this.lossAllowanceDropdownOptions = options
    }

    @action
    setLossAllowance(lossAllowance: any): void {
        this.lossAllowance = lossAllowance;
    }

    validateTariff(){
        const localValidatingErrorBody: any = [];
        let isValidTariff = true;

        if(this.isDuplicateTariff && this.selectedEffectiveStartDate !== '' && this.selectedEffectiveStartDate){
            this.pmcTariffList.map(tariff => {
                if(tariff.tariffNumber === this.pmcTariffNumber && (moment(this.selectedEffectiveStartDate).isBetween(tariff.startDate, tariff.endDate ? tariff.endDate : '9999-12-31'))
                    || tariff.tariffNumber === this.pmcTariffNumber && (moment(this.selectedEffectiveStartDate).isSame(tariff.startDate))
                )
                {
                    this.tariffDetailsToSetEndDate = tariff;
                    isValidTariff = false;
                    this.isShowEndDateConfirmationModal = true;
                }
            })
        }

        if(this.selectedSystemGroup === '' || !this.selectedSystemGroup){
            const message = 'Please select Pipeline System Name.';
            localValidatingErrorBody.push(message);
            isValidTariff = false;
        }

        if(!this.selectedEffectiveStartDate){
            const message = 'Please select Start Date.';
            localValidatingErrorBody.push(message);
            isValidTariff = false;
        }

        if(this.selectedEffectiveStartDate && this.selectedEffectiveEndDate ? moment(this.selectedEffectiveEndDate).isBefore(this.selectedEffectiveStartDate):false){
            const message = 'End Date value can not be before Start Date. Please select correct End Date.';
            localValidatingErrorBody.push(message);
            isValidTariff = false;
        }

        if(this.selectedPipelineGroup === '' || !this.selectedPipelineGroup){
            const message = 'Please select Pipeline Region Name.';
            localValidatingErrorBody.push(message);
            isValidTariff = false;
        }

        if(this.enteredOperator === '' || !this.enteredOperator){
            const message = 'Please enter Tariff Operator.';
            localValidatingErrorBody.push(message);
            isValidTariff = false;
        }

        if(this.selectedDestination === '' || !this.selectedDestination){
            const message = 'Please enter Destination value.';
            localValidatingErrorBody.push(message);
            isValidTariff = false;
        }

        if(this.selectedDistributionType === '' || !this.selectedDistributionType){
            const message = 'Please select Distribution Type.';
            localValidatingErrorBody.push(message);
            isValidTariff = false;
        }

        if(this.selectedTariffApplication === '' || !this.selectedTariffApplication){
            const message = 'Please select Tariff Application.';
            localValidatingErrorBody.push(message);
            isValidTariff = false;
        }

        if(!this.selectedProducts || (this.selectedProducts && this.selectedProducts.length === 0)){
            const message = 'Please select Product(s).';
            localValidatingErrorBody.push(message);
            isValidTariff = false;
        }

        if(this.selectedUnit === '' || !this.selectedUnit){
            const message = 'Please select Unit.';
            localValidatingErrorBody.push(message);
            isValidTariff = false;
        }

        if(this.selectedCurrency === '' || !this.selectedCurrency){
            const message = 'Please select Currency.';
            localValidatingErrorBody.push(message);
            isValidTariff = false;
        }

        if(this.selectedProductType === '' || !this.selectedProductType){
            const message = 'Please select Product Type.';
            localValidatingErrorBody.push(message);
            isValidTariff = false;
        }
        return {
            isValidTariff: isValidTariff, 
            localValidatingErrorBody: localValidatingErrorBody
        };
    }

    
    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async savePMCTariffDetailFeeDetail(): Promise<void> {
       const {isValidTariff, localValidatingErrorBody} = this.validateTariff();
       const {isValidFee} = this.validateFee();
       
       if(isValidTariff){
            if(isValidFee){
           const selectedProductIDs = this.getSelectedProductIDs();
           const selectedExternalSystemIDs = this.getSelectedExternalSystemIDs();
           const facilityID = this.selectedFacilityCode && this.selectedFacilityCode !== '' ? this.facilityCodeData.find(x=>x.valueText===this.selectedFacilityCode.label)?.settingID : null;
           const pmcTariffFeeDetail: any =  {
               'feeDetailID':this.pmcTariffFeeDetailID  ? this.pmcTariffFeeDetailID :0,
               'primaryPipelineTariff': toNumber(this.enteredPrimaryRate), 
               'secondaryRate': toNumber(this.enteredSecondaryRate),
               'surcharge': toNumber(this.enteredSurcharge),
               'lmciAbondmentS_C': toNumber(this.enteredLMCIAbandonmentRate),
               'injectionFee': toNumber(this.enteredInjectionFee),
               'receiptTerminalling': toNumber(this.enteredRecieptTerminalingRate),
               'qualityManagementFee': toNumber(this.enteredQualityMgmtFee),
               'electronicReportingFee': toNumber(this.enteredElectronicReportingFee),
               'deliveryTerminalling': toNumber(this.deliveryTerminallingFee),
               'lossAllowance': this.lossAllowance.label && this.lossAllowance.label !== 'Select' && this.lossAllowance.label != '' ? parseFloat(this.lossAllowance.value).toFixed(2) : null,
               'shrinkage': this.shrinkage.label && this.shrinkage.label != '' && this.shrinkage.label !== 'Select' ? (this.shrinkage.value).toString() : '',
               'bsw': toNumber(this.bsw),
               'intraSystemTransferFee': toNumber(this.intraSystemTransferFee),
               'lumpSumFees': toNumber(this.lumpSumFees),
               'total': this.totalFee,
               'agreement': this.enteredAgreement,
               'agreementEndDate': this.selectedAgreementEndDate ? this.selectedAgreementEndDate : null,
               'escalationMonth': this.selectedEscalationMonth.value,
               'escalationAmount': this.enteredEscalationAmount,
               'effectiveDate': new Date(moment(this.selectedEffectiveDate).format('YYYY-MM-DD')),
               'allowedDecimal':toNumber(this.ofDecimal)
           };
           const pmcTariffDetail: any =  {
            'pmcTariffDetailID': this.isTariffEditingEnabled ? this.pmcTariffDetailID : 0,
               'pmcTariffFeeDetailID': this.isTariffEditingEnabled ? this.pmcTariffFeeDetailID : 0,
               'systemGroupId':this.selectedSystemGroupID, 
               'pipelineGroupId': this.pipelineGroupData.find(x=>x.valueText===this.selectedPipelineGroup.label)?.settingID,
               'description': this.enteredOperator.label,
               'facilityId': facilityID,
               'lsd': this.selectedLSD ? this.selectedLSD.label : '',
               'destination': this.selectedDestination.label,
               'additionalDesc' : this.description,
               'distributionId': this.distributionTypeData.find(x=>x.valueText===this.selectedDistributionType.label)?.settingID,
               'tariffApplicationId': this.tariffApplicationData.find(x=>x.valueText===this.selectedTariffApplication.label)?.settingID,
               'products': selectedProductIDs,
               'createUser': this.createdUser,
               'uom': this.selectedUnit.value,
               'currency': this.selectedCurrency.value,
               'regulator': this.selectedRegulator===undefined?null:this.selectedRegulator.value,
               'externalSystem': selectedExternalSystemIDs,
               'productType': this.productTypeData.find(x=>x.valueText===this.selectedProductType.label)?.settingID,
               'tariffNumber': this.pmcTariffNumber ? this.pmcTariffNumber : 0,
               'startDate': new Date(moment(this.selectedEffectiveStartDate).format('YYYY-MM-DD')),
               'endDate': this.selectedEffectiveEndDate ? new Date(moment(this.selectedEffectiveEndDate).format('YYYY-MM-DD')) : '9999-12-31',
               'pmcTariffDetailStatus': this.tariffStatus ? this.tariffStatus: ''
        };
           const requestBody: any = {
                'pmcTariffDetail': pmcTariffDetail,
                'pmcTariffFeeDetail': pmcTariffFeeDetail
           };
           if(requestBody.length !== 0) {
               let errorSummary: ToastMessage[] = [];
               const response: any[] = await this.pmcTariffDataService.savePMCTariffDetailFeeDetail(requestBody);
               if(response){         
                   for (const key in response) {       
                       if (key==='pmcTariffDetail') {
                           if(!this.pmcTariffDetailID || this.pmcTariffDetailID === 0 ){
                               this.uiService.toastService.success('PMC Tariff Created Successfully with PMC Tariff Detail ID: ' + response[key].pmcTariffDetailID)
                           }else{
                               this.uiService.toastService.success('PMC Tariff of Detail ID: ' + response[key].pmcTariffDetailID + ' updated Successfully')
                           }
                           this.pmcTariffDetailID = response['pmcTariffDetail'].pmcTariffDetailID;
                           this.pmcTariffNumber = response['pmcTariffDetail'].tariffNumber;
                           this.tariffStatus = response['pmcTariffDetail'].pmcTariffDetailStatus;
                           this.pmcTariffFeeDetailID = response['pmcTariffDetail'].pmcTariffFeeDetailID;
                              
                            // this.uiService.toastService.success('PMC Tariff Fee Detail ID: ' + response[key])
                            this.isTariffSaved = true;
                            this.isTariffEditingEnabled = false;
                            this.isPMCTariffDuplicated = false;
                           
                          // this.savePMCFees();
                       }else {
                           if (key==='errors') {
                               for (const errorKey in response[key]) { 
                                   errorSummary = [...errorSummary, { id: errorKey, description: response[key][errorKey].toString() }];
                               }                        
                               this.uiService.toastService.error('', {}, errorSummary);
                           }
                           if(key==='statusCode' && response[key] !== 200){
                               errorSummary = [...errorSummary, { id: '1', description: 'Error in saving tariff' }];
                               this.uiService.toastService.error('', {}, errorSummary);
                           }
                       }
                   }
                   dataStore.setShowUnsavedWarningAlert(false);
               } 
           }  
       }
    }else if(!this.isDuplicateTariff){
           const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
           errorHandler(messages);
       } else if (this.isDuplicateTariff && !this.selectedEffectiveStartDate) {
           const message = 'Please select Start Date.';
           errorHandler(message);
       }
   }
   

     @Loader
     @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async savePMCTariff(): Promise<void> {
        const {isValidTariff, localValidatingErrorBody} = this.validateTariff();
        
        if(isValidTariff){
            const selectedProductIDs = this.getSelectedProductIDs();
            const selectedExternalSystemIDs = this.getSelectedExternalSystemIDs();
            const facilityID = this.selectedFacilityCode && this.selectedFacilityCode !== '' ? this.facilityCodeData.find(x=>x.valueText===this.selectedFacilityCode.label)?.settingID : null;

            const requestBody: any = {
                'tariffNumber': this.pmcTariffNumber ? this.pmcTariffNumber : 0,
                'pmcTariffDetailID': this.isTariffEditingEnabled ? this.pmcTariffDetailID : 0,
                'pmcTariffFeeDetailID': this.isTariffEditingEnabled ? this.pmcTariffFeeDetailID : 0,
                'startDate': new Date(moment(this.selectedEffectiveStartDate).format('YYYY-MM-DD')),
                'endDate': this.selectedEffectiveEndDate ? new Date(moment(this.selectedEffectiveEndDate).format('YYYY-MM-DD')) : '9999-12-31',
                'systemGroupId':this.selectedSystemGroupID, 
                'pipelineGroupId': this.pipelineGroupData.find(x=>x.valueText===this.selectedPipelineGroup.label)?.settingID,
                'description': this.enteredOperator.label,
                'facilityId': facilityID,
                'lsd': this.selectedLSD ? this.selectedLSD.label : '',
                'destination': this.selectedDestination.label,
                'additionalDesc' : this.description,
                'distributionId': this.distributionTypeData.find(x=>x.valueText===this.selectedDistributionType.label)?.settingID,
                'tariffApplicationId': this.tariffApplicationData.find(x=>x.valueText===this.selectedTariffApplication.label)?.settingID,
                'productType': this.productTypeData.find(x=>x.valueText===this.selectedProductType.label)?.settingID,
                'products': selectedProductIDs,
                'createUser': this.createdUser,
                'uom': this.selectedUnit.value,
                'currency': this.selectedCurrency.value,
                'regulator': this.selectedRegulator.value,
                'externalSystem': selectedExternalSystemIDs,
            };
            if(requestBody.length !== 0) {
                let errorSummary: ToastMessage[] = [];
                const response: any[] = await this.pmcTariffDataService.savePMCTariff(requestBody);
                if(response){         
                    for (const key in response) {       
                        if (key==='pmcTariffDetailID') {
                            if(this.pmcTariffDetailID === 0){
                                this.uiService.toastService.success('PMC Tariff Created Successfully with PMC Tariff Detail ID: ' + response[key])
                            }else{
                                this.uiService.toastService.success('PMC Tariff of Detail ID: ' + response[key] + ' updated Successfully')
                            }
                            this.pmcTariffDetailID = response['pmcTariffDetailID'];
                            this.pmcTariffNumber = response['tariffNumber'];
                            this.tariffStatus = response['pmcTariffDetailStatus'];
                            this.pmcTariffFeeDetailID = response['pmcTariffFeeDetailID'];
                            this.savePMCFees();
                        }else {
                            if (key==='errors') {
                                for (const errorKey in response[key]) { 
                                    errorSummary = [...errorSummary, { id: errorKey, description: response[key][errorKey].toString() }];
                                }                        
                                this.uiService.toastService.error('', {}, errorSummary);
                            }
                            if(key==='statusCode' && response[key] !== 200){
                                errorSummary = [...errorSummary, { id: '1', description: 'Error in saving tariff' }];
                                this.uiService.toastService.error('', {}, errorSummary);
                            }
                        }
                    }
                    dataStore.setShowUnsavedWarningAlert(false);
                } 
            }  
        }else if(!this.isDuplicateTariff){
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        } else if (this.isDuplicateTariff && !this.selectedEffectiveStartDate) {
            const message = 'Please select Start Date.';
            errorHandler(message);
        }
    }
    
    @Loader
    @action
    @Catch((error) => { console.log(error); errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString()) })
    async deletePMCTariff(): Promise<void> {
        const response: any[] = await this.pmcTariffDataService.deletePMCTariff(this.pmcTariffDetailID);
        console.log(response)
        if (response) {
            this.uiService.toastService.success('PMC Tariff deleted Successfully.')
            if(this.previousLoc==='/PMCGridView'){
                history.push('/PMCGridView')
            } else {
                history.push('/PMCDashboard')
            }
            this.resetPMCTariff();
        }else{
            this.uiService.toastService.success('There is an error while deleteing PMC Tariff.')
        }
    }

     validateFee(){
        const localValidatingErrorBody: any = [];
        let isValidFee = true;
        if(!this.selectedEffectiveDate){
            const message = 'Please select Effective Date.';
            localValidatingErrorBody.push(message);
            isValidFee = false;
        }
        return {
            isValidFee: isValidFee, 
            localValidatingErrorBody: localValidatingErrorBody
        };
    }

     @Loader
     @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async savePMCFees(): Promise<void> {
        const {isValidFee, localValidatingErrorBody} = this.validateFee();

        if(isValidFee){
        const requestBody: any =  {
            'feeDetailID':this.pmcTariffFeeDetailID,
            'primaryPipelineTariff': toNumber(this.enteredPrimaryRate), 
            'secondaryRate': toNumber(this.enteredSecondaryRate),
            'surcharge': toNumber(this.enteredSurcharge),
            'lmciAbondmentS_C': toNumber(this.enteredLMCIAbandonmentRate),
            'injectionFee': toNumber(this.enteredInjectionFee),
            'receiptTerminalling': toNumber(this.enteredRecieptTerminalingRate),
            'qualityManagementFee': toNumber(this.enteredQualityMgmtFee),
            'electronicReportingFee': toNumber(this.enteredElectronicReportingFee),
            'deliveryTerminalling': toNumber(this.deliveryTerminallingFee),
            'lossAllowance': this.lossAllowance.label && this.lossAllowance.label !== 'Select' && this.lossAllowance.label != '' ? parseFloat(this.lossAllowance.value).toFixed(2) : null,
            'shrinkage': this.shrinkage.label && this.shrinkage.label != '' && this.shrinkage.label !== 'Select' ? (this.shrinkage.value).toString() : '',
            'bsw': toNumber(this.bsw),
            'intraSystemTransferFee': toNumber(this.intraSystemTransferFee),
            'lumpSumFees': toNumber(this.lumpSumFees),
            'total': this.totalFee,
            'effectiveDate': new Date(moment(this.selectedEffectiveDate).format('YYYY-MM-DD')),
            'agreement': this.enteredAgreement,
            'agreementEndDate': this.selectedAgreementEndDate ? this.selectedAgreementEndDate : null,
            'escalationMonth': this.selectedEscalationMonth.value,
            'escalationAmount': this.enteredEscalationAmount,
            'allowedDecimal':toNumber(this.ofDecimal)
        };
        if(requestBody.length !== 0) {
            let errorSummary: ToastMessage[] = [];
            const response: any[] = await this.pmcTariffDataService.savePMCFeeDetails(requestBody);
            if(response){
                for (const key in response) {    
                    if (key==='feeDetailID') {       
                        // this.uiService.toastService.success('PMC Tariff Fee Detail ID: ' + response[key])
                        this.isTariffSaved = true;
                        this.isTariffEditingEnabled = false;
                        this.isPMCTariffDuplicated = false;
                    }else {
                        if (key==='errors') {
                            for (const errorKey in response[key]) { 
                                errorSummary = [...errorSummary, { id: errorKey, description: response[key][errorKey].toString() }];
                            }                        
                            this.uiService.toastService.error('', {}, errorSummary);
                        }
                    }
                }
                dataStore.setShowUnsavedWarningAlert(false);
            }
        }        
        }else{
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        }
    }

    @action
    handleCloseEndDateConfirmationModal(){
        this.tariffDetailsToSetEndDate = {};
        this.isShowEndDateConfirmationModal = false;
    }

    @Loader
    @action
    @Catch((error) => { console.log(error); errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString()) })
    async duplicatePMCTariff(): Promise<void> {
        const {isValidFee, localValidatingErrorBody} = this.validateFee();

        if (isValidFee) {
            const pmcTariffID = this.pmcTariffDetailID;
            const userName = this.createdUser;
            const response: any[] = await this.pmcTariffDataService.duplicatePMCTariff(pmcTariffID, userName);
            const assetGroupID = this.tariffRequestTypeData.find(x => x.name === response[0].assetGroup)?.assetGroupID;
            const reqBody = [{ 'propertyName': 'STARTDATE', 'value': new Date(moment(new Date()).startOf('month').format('YYYY-MM-DD')) }];
            const existingTariffList = await this.pmcTariffDataService.getPMCDashboardDetails(reqBody);
            this.resetPMCTariff();

            runInAction(() => {
                this.pmcTariffList = existingTariffList;
                this.isDuplicateTariff = true;
                this.isPMCTariffDuplicated = true;
                this.assetGroupID = assetGroupID;
                this.isTariffSaved = false;
                this.isTariffEditingEnabled = true;
                this.setTariffRequestType({ 'value': response[0].assetGroup, 'label': response[0].assetGroup });
            
                this.pmcTariffNumber = response[0].tariffNumber;
                this.tariffStatus = response[0].pmcTariffDetailStatus;
                this.pmcTariffDetailID = response[0].pmcTariffDetailID;
                this.pmcTariffFeeDetailID = response[0].pmcTariffFeeDetailID;
                this.setAgreement(response[0].agreement);
                this.selectedAgreementEndDate = response[0].agreementEndDate ? new Date(moment(response[0].agreementEndDate).format('YYYY-MM-DD')) : '';
                this.setBSW(Number(response[0].bS_WPenalty));
                const currencyValue = this.currencyData.find(x => x.settingID === response[0].currency)?.valueText;
                this.setCurrency({ 'value': response[0].currency, 'label': currencyValue });
                const regulatorValue = this.regulatorData.find(x => x.settingID === response[0].regulator)?.valueText;
                this.setRegulator({ 'value': response[0].regulator, 'label': regulatorValue });
                this.setDeliveryTerminallingFee(Number(response[0].deliveryTerminalling));
                this.setDestination({ 'value': response[0].destination, 'label': response[0].destination });
                this.setDescription(response[0].description);
                this.setDistributionType({ 'value': response[0].distribution, 'label': response[0].distribution });
                this.selectedEffectiveDate = response[0].effectiveDate ? new Date(response[0].effectiveDate) : '';
                this.setElectronicReportingFee(Number(response[0].electronicReportingFee));
                this.setEscalationAmount(response[0].escalationAmount);
                this.setEscalationMonth({ 'value': response[0].escalationMonth, 'label': this.monthNames.filter(x=>x.value == response[0].escalationMonth)[0]?.label });
                const externalSystems = response[0].externalSystem.length > 0 ? response[0].externalSystem.split(',') : [];
                this.setExternalSystems(externalSystems);
                this.setFacilityCode({ 'value': response[0].pmcFacility, 'label': response[0].pmcFacility });
                this.setIntraSystemTransferFee(Number(response[0].intraSystemTransferFee));
                this.setLSD({ 'value': response[0].lsd, 'label': response[0].lsd });
                const lossAllowanceValue = response[0].lossAllowance || response[0].lossAllowance != '' ? this.lossAllowanceData.find(x => x.valueText === response[0].lossAllowance)?.settingID : '';
                this.setLossAllowance({ 'value': lossAllowanceValue, 'label': response[0].lossAllowance });
                this.setLumpSumFees(Number(response[0].lumpSumFees));
                this.setOperator({'value': response[0].operator, 'label': response[0].operator});
                this.setPipelineGroup({ 'value': response[0].pmcPipelineGroup, 'label': response[0].pmcPipelineGroup });
                this.setPrimaryRate(Number(response[0].primaryPipelineTariff));
                const products = response[0].product.length > 0 ? response[0].product.split(',') : [];
                this.setProducts(products);
                this.setProductType({ 'value': response[0].productTypeID, 'label': response[0].productType });
                this.setQualityMgmtFee(Number(response[0].qualityManagementFee));
                this.setSecondaryRate(Number(response[0].secondaryPipelineTariff));
                this.setReceiptTerminalling(Number(response[0].receiptTerminalling));
                this.setLmciAbandonment(Number(response[0].lmciAbandonmentS_C));
                this.setInjectionFee(Number(response[0].offload_InjectionFee));
                this.setSurcharge(Number(response[0].surcharge));
                const shrinkageValue = response[0].shrinkage || response[0].shrinkage != '' ? this.shrinkageData.find(x => x.valueText === response[0].shrinkage)?.settingID : '';
                this.setShrinkage({ 'value': shrinkageValue, 'label': response[0].shrinkage });
                this.setSystemGroup({ 'value': response[0].pmcSystemGroup, 'label': response[0].pmcSystemGroup });
                this.setTariffApplication({ 'value': response[0].tariffApplication, 'label': response[0].tariffApplication });
                const unitValue = this.unitData.find(x => x.settingID === response[0].unit)?.valueText;
                this.setUnit({ 'value': response[0].unit, 'label': unitValue });
                this.totalFee = Number(response[0].total);
                this.setOfDecimal(Number(response[0].allowedDecimal));

                this.setPMCTariffAttachmentGridData(response[0].pmcDocs);
                this.setPMCTariffNoteGridData(response[0].pmcNotes);

            })
        } else {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        }
    }

    @action
    async setTariffEndDate() : Promise<void> {
        const tariff = this.tariffDetailsToSetEndDate;
        let externalSystemIDs;
        if (moment(this.selectedEffectiveStartDate).subtract(1, 'days').isBefore(tariff.startDate)) {
            const message = 'End Date value can not be set before Start Date of existing tariff. Please select correct Start Date of current tariff to set End date of existing tariff.';
            errorHandler(message);
        } else {
            const products = tariff.product.split(',');
            const productIDs = products.map((item) => {
                return this.productsData.find(x => x.valueText === item)?.settingID;
            })
            if (tariff.externalSystem && tariff.externalSystem.length > 0) {
                const externalSystems = tariff.externalSystem.split(',');
                externalSystemIDs = externalSystems.map((item) => {
                    return this.externalSystemData.find(x => x.valueText === item)?.settingID;
                })
            }
            const requestBody: any = {
                'tariffNumber': tariff.tariffNumber,
                'pmcTariffDetailID': tariff.pmcTariffDetailID,
                'pmcTariffFeeDetailID': tariff.pmcTariffFeeDetailID,
                'startDate': tariff.startDate,
                'endDate': moment(this.selectedEffectiveStartDate).subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss').toString(),
                'systemGroupId': this.systemGroupData.find(x => x.valueText === tariff.pmcSystemGroup)?.settingID,
                'pipelineGroupId': this.pipelineGroupData.find(x => x.valueText === tariff.pmcPipelineGroup)?.settingID,
                'description': tariff.operator,
                'facilityId': tariff.pmcFacility && tariff.pmcFacility !== '' ? this.facilityCodeData.find(x => x.valueText === tariff.pmcFacility)?.settingID : null,
                'lsd': tariff.lsd,
                'destination': tariff.destination,
                'additionalDesc':tariff.description,
                'distributionId': this.distributionTypeData.find(x => x.valueText === tariff.distribution)?.settingID,
                'tariffApplicationId': this.tariffApplicationData.find(x => x.valueText === tariff.tariffApplication)?.settingID,
                'productType': this.productTypeData.find(x => x.valueText === tariff.productType)?.settingID,
                'products': productIDs.toString(),
                'createUser': tariff.createUser,
                'uom': tariff.unit,
                'currency': tariff.currency,
                'regulator': tariff.regulator,
                'externalSystem': externalSystemIDs && externalSystemIDs.length > 0 ? externalSystemIDs.toString() : null,
            };
            const response: any = await this.pmcTariffDataService.savePMCTariff(requestBody);

            if (response && response.pmcTariffDetailID) {
                this.uiService.toastService.success(`End date of existing Tariff is successfully updated to ${moment(this.selectedEffectiveStartDate).subtract(1, 'days').format('YYYY-MM-DD').toString()}`);
                this.tariffDetailsToSetEndDate = {};
                this.isShowEndDateConfirmationModal = false;
                this.isDuplicateTariff = false;
                this.savePMCTariff();
            } else {
                this.uiService.toastService.error('There is an error while updating End date of existing Tariff.');
            }
        }
    }

    @action
    resetPMCTariff(){
        this.selectedTariffRequestType = '';
        this.isTariffSaved = false;
        this.isDuplicateTariff = false;
        this.isPMCTariffDuplicated = false;
        this.isTariffEditingEnabled = false;
        this.isLossAllowanceEditable = false;
        this.isShrinkageEditable = false;
        this.isBSWEditable = false;
        this.isIntraSystemTransferFeeEditable = false;
        this.isQualityMgmtFeeEditable = false;
        this.isElectronicReportingFeeEditable = false;
        this.pmcTariffDetailID = '';
        this.pmcTariffNumber = '';
        this.tariffStatus = '';
        this.assetGroupID = null;
        this.pmcTariffFeeDetailID = '';
        this.notesGridData = [];
        this.fileUploadGridData = [];
        this.backupNotesGridData = [];
        this.backupAttachmentGridData = [];
        this.selectedSystemGroup = '';
        this.selectedSystemGroupID = '';
        this.selectedPipelineGroup = '';
        this.selectedTariffApplication = '';
        this.description ='';
        this.selectedDistributionType = '';
        this.selectedExternalSystems = '';
        this.selectedProducts = '';
        this.enteredOperator = '';
        this.selectedDestination = '';
        this.selectedEffectiveDate = '';
        this.selectedEffectiveStartDate = '';
        this.selectedEffectiveEndDate = '';
        this.selectedFacilityCode = '';
        this.selectedLSD = '';
        this.enteredAgreement = '';
        this.selectedProductType = '';
        this.selectedUnit = '';
        this.selectedCurrency = '';
        this.selectedRegulator = '';
        this.enteredPrimaryRate = 0; 
        this.enteredSecondaryRate = 0;
        this.enteredSurcharge = 0;
        this.enteredLMCIAbandonmentRate = 0;
        this.enteredInjectionFee = 0;
        this.enteredRecieptTerminalingRate = 0;
        this.enteredQualityMgmtFee = 0;
        this.enteredElectronicReportingFee = 0;
        this.deliveryTerminallingFee = 0;
        this.lossAllowance = { value: 'Select', label: 'Select' };
        this.shrinkage = { value: 'Select', label: 'Select' };
        this.bsw = 0;
        this.intraSystemTransferFee = 0;
        this.lumpSumFees = 0;
        this.totalFee = 0;
        this.enteredAgreement = '';
        this.selectedAgreementEndDate = null;
        this.selectedEscalationMonth = { value: 'Select', label: 'Select' };
        this.enteredEscalationAmount = '';
        this.previousLoc = '';
        this.ofDecimal = 2;
    }

    @Loader
    @action
    resetTariffEditingData() {
        this.isTariffSaved = true;
        this.isTariffNumberClicked = true;
        this.setTariffRequestType({ 'value': this.editableTariffData[0].assetGroup, 'label': this.editableTariffData[0].assetGroup});
        
        this.pmcTariffNumber = this.editableTariffData[0].tariffNumber;
        this.tariffStatus = this.editableTariffData[0].pmcTariffDetailStatus;
        this.pmcTariffDetailID = this.editableTariffData[0].pmcTariffDetailID;
        this.pmcTariffFeeDetailID = this.editableTariffData[0].pmcTariffFeeDetailID;
        this.selectedSystemGroupID = this.systemGroupData.find(x => x.valueText === this.editableTariffData[0].pmcSystemGroup)?.settingID;
        this.setSystemGroup({'value': this.editableTariffData[0].pmcSystemGroup, 'label': this.editableTariffData[0].pmcSystemGroup});
        this.setAgreement(this.editableTariffData[0].agreement);
        this.selectedAgreementEndDate = this.editableTariffData[0].agreementEndDate ? new Date(moment(this.editableTariffData[0].agreementEndDate).format('YYYY-MM-DD')) : '';
        this.setBSW(Number(this.editableTariffData[0].bS_WPenalty));
        const currencyValue = this.currencyData.find(x=>x.settingID===this.editableTariffData[0].currency)?.valueText;
        this.setCurrency({ 'value': this.editableTariffData[0].currency, 'label': currencyValue});
        const regulatorValue = this.regulatorData.find(x=>x.settingID===this.editableTariffData[0].regulator)?.valueText;
        this.setRegulator({ 'value': this.editableTariffData[0].regulator, 'label': regulatorValue});
        this.setDeliveryTerminallingFee(Number(this.editableTariffData[0].deliveryTerminalling));
        this.setDestination({ 'value': this.editableTariffData[0].destination, 'label': this.editableTariffData[0].destination});
        this.setDescription(this.editableTariffData[0].description);
        this.setDistributionType({ 'value': this.editableTariffData[0].distribution, 'label': this.editableTariffData[0].distribution});
        this.selectedEffectiveDate = this.editableTariffData[0].effectiveDate ? new Date(this.editableTariffData[0].effectiveDate) : '';
        this.setElectronicReportingFee(Number(this.editableTariffData[0].electronicReportingFee));
        this.setEscalationAmount(this.editableTariffData[0].escalationAmount);
        this.setEscalationMonth({ 'value': this.editableTariffData[0].escalationMonth, 'label': this.monthNames.filter(x=>x.value == this.editableTariffData[0].escalationMonth)[0]?.label });
        const externalSystems =  this.editableTariffData[0].externalSystem.length > 0 ? this.editableTariffData[0].externalSystem.split(',') : [];
        this.setExternalSystems(externalSystems);
        this.setFacilityCode({'value': this.editableTariffData[0].pmcFacility, 'label': this.editableTariffData[0].pmcFacility});
        this.setIntraSystemTransferFee(Number(this.editableTariffData[0].intraSystemTransferFee));
        this.setLSD({'value': this.editableTariffData[0].lsd, 'label': this.editableTariffData[0].lsd});
        const lossAllowanceValue = this.editableTariffData[0].lossAllowance || this.editableTariffData[0].lossAllowance != '' ? this.lossAllowanceData.find(x=>x.valueText===this.editableTariffData[0].lossAllowance)?.settingID : '';
        this.setLossAllowance({'value': lossAllowanceValue, 'label': this.editableTariffData[0].lossAllowance});
        this.setLumpSumFees(Number(this.editableTariffData[0].lumpSumFees));
        this.setOperator({'value': this.editableTariffData[0].operator, 'label': this.editableTariffData[0].operator});
        this.setPipelineGroup({'value': this.editableTariffData[0].pmcPipelineGroup, 'label': this.editableTariffData[0].pmcPipelineGroup});
        this.setPrimaryRate(Number(this.editableTariffData[0].primaryPipelineTariff));
        const products =  this.editableTariffData[0].product.length > 0 ? this.editableTariffData[0].product.split(',') : [];
        this.setProducts(products);
        this.setProductType({'value': this.editableTariffData[0].productTypeID, 'label': this.editableTariffData[0].productType});
        this.setQualityMgmtFee(Number(this.editableTariffData[0].qualityManagementFee));
        this.setSecondaryRate(Number(this.editableTariffData[0].secondaryPipelineTariff));
        this.setReceiptTerminalling(Number(this.editableTariffData[0].receiptTerminalling));
        this.setLmciAbandonment(Number(this.editableTariffData[0].lmciAbandonmentS_C));
        this.setInjectionFee(Number(this.editableTariffData[0].offload_InjectionFee));
        this.setSurcharge(Number(this.editableTariffData[0].surcharge));
        const shrinkageValue = this.editableTariffData[0].shrinkage || this.editableTariffData[0].shrinkage != '' ? this.shrinkageData.find(x=>x.valueText===this.editableTariffData[0].shrinkage)?.settingID : '';
        this.setShrinkage({'value': shrinkageValue, 'label': this.editableTariffData[0].shrinkage});
        this.setTariffApplication({'value': this.editableTariffData[0].tariffApplication, 'label': this.editableTariffData[0].tariffApplication});
        const unitValue = this.unitData.find(x=>x.settingID===this.editableTariffData[0].unit)?.valueText;
        this.setUnit({'value': this.editableTariffData[0].unit, 'label': unitValue});
        this.totalFee = Number(this.editableTariffData[0].total);
        this.selectedEffectiveStartDate = new Date(this.editableTariffData[0].startDate);
        this.selectedEffectiveEndDate = this.editableTariffData[0].endDate ? new Date(this.editableTariffData[0].endDate) : '';
        this.setOfDecimal(Number(this.editableTariffData[0].allowedDecimal));
    }
 
    @action
    setPMCTariffNoteGridData(notesGridData: PMCNotesResponse[]): void {
        this.notesGridData = notesGridData
    }

    @computed
    get NotesGridData() {
        return toJS(this.notesGridData);
    }

    @action
    setPMCTariffAttachmentGridData(attachmentGridData: any[]): void {
        this.fileUploadGridData = attachmentGridData;
    }

    @computed
    get AttachmentsGridData() {
        return toJS(this.fileUploadGridData);
    }

    @action
    updateShowUnsavedWarningAlert = (): void => {
        dataStore.setShowUnsavedWarningAlert(true);
    };

    @action 
    scrollToFocusTheNewCell() {
        this.agGridService.scrollToSelectedRow();
    }

    @action
    updateRow = (selectedRowData: any): void => {
        const updatedShopIndex = this.notesGridData.findIndex(a => a.pmcTariffNoteID == selectedRowData.pmcTariffNoteID);
        if (!_.isEqual(this.backupNotesGridData[updatedShopIndex], selectedRowData)) {
            this.mapEditableColumns(this.notesGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        } 
    };

    @action
    updateRowAttachment = (selectedRowData: any): void => {
        const updatedShopIndex = this.fileUploadGridData.findIndex(a => a.pmcTariffDocumentID == selectedRowData.pmcTariffDocumentID);
        if (!_.isEqual(this.backupAttachmentGridData[updatedShopIndex], selectedRowData)) {
            this.mapAttachmentEditableColumns(this.fileUploadGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        } 
    };

    mapEditableColumns(currentItem: any, updatedItem: any) {
        currentItem.pmcTariffNoteID = updatedItem.pmcTariffNoteID;
        currentItem.notes = updatedItem.notes;
        currentItem.createUser = updatedItem.createUser;
        currentItem.createDate = updatedItem.createDate;
    }

    mapAttachmentEditableColumns(currentItem: any, updatedItem: any) {
        currentItem.pmcTariffDocumentID = updatedItem.pmcTariffDocumentID;
        currentItem.description = updatedItem.description;
        currentItem.updateUser = updatedItem.updateUser;
        currentItem.updateDate = updatedItem.updateDate;
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        // get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMapAttachment) {
            for (const col in this.cellValueChangeMapAttachment[row]) {
                const obj = this.cellValueChangeMapAttachment[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async saveTariffNotes(): Promise<void> {
    const gridData = this.notesGridData.filter(note => note.notes !=='')
    let requestBodyForNote: any = {};
    if(this.isTariffSaved){
        const diff = _.differenceWith(gridData, this.backupNotesGridData, _.isEqual);
        requestBodyForNote = {
        'TariffID': this.pmcTariffDetailID, 
        'user': this.createdUser,
        'notes': diff.map(note => {
            return {
                'id': note.pmcTariffNoteID ? note.pmcTariffNoteID : 0,
                'notes': note.notes,
            }            
        })
        }
    }else {
        requestBodyForNote = {
        'TariffID': this.pmcTariffDetailID, 
        'user': this.createdUser,
        'notes': gridData.map(note => {
            return {
                'id': note.pmcTariffNoteID ? note.pmcTariffNoteID : 0,
                'notes': note.notes,
            }            
        })
        }
    }

    if(requestBodyForNote.notes.length !== 0) {
        let errorSummary: ToastMessage[] = [];
        let errorInSave = false;
        const response: any[] = await this.pmcTariffDataService.savePMCTariffNotes(requestBodyForNote);
        if(response){
            for (const key in response) {   
                if(response.length > 0){
                    this.setPMCTariffNoteGridData(response);
                    this.backupNotesGridData = response;
                    this.isTariffNoteSaved = true;
                    dataStore.setShowUnsavedWarningAlert(false);
                }
                else {
                    errorInSave = true;
                    errorSummary = [...errorSummary, { id: key, description: 'There is an error saving note' }];
                }
            }
        }
        if (errorInSave) {
            this.uiService.toastService.error('', {}, errorSummary);
            errorSummary.forEach(errorMessage => {
                for (let i = 0; i < this.notesGridData.length; i++) {
                    const updatedData = this.notesGridData[i];
                    if (updatedData.pmcTariffNoteID.toString() === errorMessage.id) {
                        const backupRowIndex = this.notesGridData.findIndex(a => a.pmcTariffNoteID.toString() == errorMessage.id);
                        this.mapEditableColumns(updatedData, this.notesGridData[backupRowIndex]);
                    }
                }
            });
        }
        else {
            this.uiService.toastService.success(PMCTariffMessages.NOTE_SAVE);
        }
    }        
    }

     @Loader
     @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async deleteTariffNote(noteID): Promise<void> {
        if(noteID) {
            const response: any = await this.pmcTariffDataService.deletePMCTariffNote(noteID);
            if(response){ 
                const nodesGridData = this.NotesGridData.filter((item) => item.pmcTariffNoteID !== noteID);
                this.setPMCTariffNoteGridData(nodesGridData);
                this.uiService.toastService.success('Note has deleted successfully')
            }else {
                this.uiService.toastService.error('There is an error while deleting selected note');
            }
            dataStore.setShowUnsavedWarningAlert(false);
        } 
    }

    @action
    setPrimaryRate(primaryRate: any): void {
        if(!isNaN(primaryRate))
        this.enteredPrimaryRate = primaryRate;
        this.updateTotalFee();
    }

    // @action
    // setIsPrimaryRateEditable(isPrimaryRateEditable: boolean): void {
    //     this.isPrimaryRateEditable = isPrimaryRateEditable;
    // }

    @action
    setSecondaryRate(secondaryRate: any): void {
        if(!isNaN(secondaryRate))
        this.enteredSecondaryRate = secondaryRate;
        this.updateTotalFee();
    }

    @action
    setReceiptTerminalling(receiptTerminalling: any): void {
        if(!isNaN(receiptTerminalling))
        this.enteredRecieptTerminalingRate = receiptTerminalling;
        this.updateTotalFee();
    }

    @action
    setLmciAbandonment(lmciAbandonment: any): void {
        if(!isNaN(lmciAbandonment))
        this.enteredLMCIAbandonmentRate = lmciAbandonment;
        this.updateTotalFee();
    }

    @action
    setInjectionFee(injectionFee: any): void {
        if(!isNaN(injectionFee))
        this.enteredInjectionFee = injectionFee;
        this.updateTotalFee();
    }

    @action
    setSurcharge(surchange: any): void {
        if(!isNaN(surchange))
        this.enteredSurcharge = surchange;
        this.updateTotalFee();
    }

    @action
    setIsLossAllowanceEditable(isLossAllowanceEditable: boolean): void {
        this.isLossAllowanceEditable = isLossAllowanceEditable;
    }

    @action
    setQualityMgmtFee(qualityMgmtFee: any): void {
        if(!isNaN(qualityMgmtFee))
        this.enteredQualityMgmtFee = qualityMgmtFee;
        this.updateTotalFee();
    }

    @action
    setIsQualityMgmtFeeEditable(isQualityMgmtFeeEditable: boolean): void {
        this.isQualityMgmtFeeEditable = isQualityMgmtFeeEditable;
    }

    @action
    setElectronicReportingFee(electronicReportingFee: any): void {
        if(!isNaN(electronicReportingFee))
        this.enteredElectronicReportingFee = electronicReportingFee;
        this.updateTotalFee();
    }

    @action
    setIsElectronicReportingFeeEditable(isElectronicReportingFeeEditable: boolean): void {
        this.isElectronicReportingFeeEditable = isElectronicReportingFeeEditable;
    }

    @action
    setIsShrinkageEditable(isShrinkageEditable: boolean): void {
        this.isShrinkageEditable = isShrinkageEditable;
    }

    @action
    setBSW(bsw: any): void {
        if(!isNaN(bsw))
        this.bsw = bsw;
    }

    @action
    setIsBSWEditable(isBSWEditable: boolean): void {
        this.isBSWEditable = isBSWEditable;
    }

    @action
    setDeliveryTerminallingFee(deliveryTerminallingFee: any): void {
        if(!isNaN(deliveryTerminallingFee))
        this.deliveryTerminallingFee = deliveryTerminallingFee;
    }

    @action
    setIntraSystemTransferFee(intraSystemTransferFee: any): void {
        if(!isNaN(intraSystemTransferFee))
        this.intraSystemTransferFee = intraSystemTransferFee;
    }

    @action
    setIsIntraSystemTransferFeeEditable(isIntraSystemTransferFeeEditable: boolean): void {
        this.isIntraSystemTransferFeeEditable = isIntraSystemTransferFeeEditable;
    }

    @action
    setLumpSumFees(lumpSumFees: any): void {
        if(!isNaN(lumpSumFees))
        this.lumpSumFees = lumpSumFees;
    }

    @action
    setOfDecimal(ofDecimal: any): void {
        if(ofDecimal > 6)
            {
                this.uiService.toastService.error('value should be between 2 to 6')

            }
            else{    
                this.ofDecimal = ofDecimal < 2 ? 2 :ofDecimal;         
                if(!isNaN(ofDecimal) && ofDecimal > 1)
                    {
                this.setPrimaryRate(Number(this.enteredPrimaryRate).toFixed(ofDecimal));
                   this.setSecondaryRate(Number(this.enteredSecondaryRate).toFixed(ofDecimal));
                   this.setQualityMgmtFee(Number(this.enteredQualityMgmtFee).toFixed(ofDecimal));
                   this.setElectronicReportingFee(Number(this.enteredElectronicReportingFee).toFixed(ofDecimal));
                   this.setBSW(Number(this.bsw).toFixed(ofDecimal));
                   this.setDeliveryTerminallingFee(Number(this.deliveryTerminallingFee).toFixed(ofDecimal));
                   this.setIntraSystemTransferFee(Number(this.intraSystemTransferFee).toFixed(ofDecimal));
                   this.setReceiptTerminalling(Number(this.enteredRecieptTerminalingRate).toFixed(ofDecimal)); 
                   this.setInjectionFee(Number(this.enteredInjectionFee).toFixed(ofDecimal));
                   this.setLmciAbandonment(Number(this.enteredLMCIAbandonmentRate).toFixed(ofDecimal));
                   this.setSurcharge(Number(this.enteredSurcharge).toFixed(ofDecimal));
                   this.setLumpSumFees(Number(this.lumpSumFees).toFixed(Number(ofDecimal)));
                    }
               
            }
    }

  

    updateTotalFee(){
        const totalFee : number = _.sum([toNumber(this.enteredPrimaryRate), toNumber(this.enteredSecondaryRate), toNumber(this.enteredSurcharge), toNumber(this.enteredLMCIAbandonmentRate), toNumber(this.enteredInjectionFee), toNumber(this.enteredRecieptTerminalingRate), toNumber(this.enteredQualityMgmtFee), toNumber(this.enteredElectronicReportingFee)]);
        this.totalFee = parseFloat(totalFee.toFixed(this.ofDecimal));
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in uploading attachment '))
    async uploadFile(e): Promise<void> {
        const formData = new FormData();
        formData.append('Attachment', e.target.files[0]);
        formData.append('FileName', e.target.files[0].name);
        formData.append('Description', 'File Description');
        formData.append('User', this.createdUser );
        formData.append('TariffID', this.pmcTariffDetailID);
        const uploadedFileData: any = await this.pmcTariffDataService.uploadFile(formData);
        if(uploadedFileData.length > 0){
            this.uiService.toastService.success('Attachment uploaded successfully');
            // const arrUploadedFileData: any = [];
            // uploadedFileData.map(item => {
            //     arrUploadedFileData.push(item);
            // })
            // const fileGridData = this.fetchFileGridData(arrUploadedFileData);
            const documents = JSON.parse(JSON.stringify(uploadedFileData));
            const attachmentList = this.backupAttachmentGridData.concat(documents)
            this.setPMCTariffAttachmentGridData(attachmentList);
            this.backupAttachmentGridData = attachmentList;
            e.target.value = '';
        }else {
            this.uiService.toastService.error('There is an error while uploading attachment');
        }        
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in downloading attachment '))
    async downloadAttachment(fileID): Promise<void> {
        const response: any = await this.pmcTariffDataService.downloadAttachment(fileID);
        window.open(response, '_blank', 'noreferrer');
        this.uiService.toastService.success('Attachment downloaded successfully');
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in deleting attachment '))
    async deleteAttachment(docID): Promise<void> {
        const response: any = await this.pmcTariffDataService.deleteAttachment(docID);
        if(response){
            const filteredData = this.AttachmentsGridData.filter((item) =>  item.pmcTariffDocumentID !== docID);
            this.uiService.toastService.success('Attachment deleted successfully');
            this.setPMCTariffAttachmentGridData(filteredData);
            this.backupAttachmentGridData = this.fileUploadGridData;
        }else {
            this.uiService.toastService.error('There is an error while deleting attachment');
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in editing attachment '))
    async updateAttachment(): Promise<void> {
        const filesToUpdate: any = [];
        const updatedRowsIDS = this.getUpdatedRowIDs();
        const documents = JSON.parse(JSON.stringify(this.fileUploadGridData));
        if(updatedRowsIDS.length>0){
            updatedRowsIDS.map(docId=>{
                const updatedRow: any = documents.find(item=>item.pmcTariffDocumentID == docId)
                filesToUpdate.push({
                    'id': updatedRow.pmcTariffDocumentID,
                    'feeDetailID': updatedRow.pmcTariffDetailID,
                    'user': updatedRow.createUser,
                    'description': updatedRow.description
                })
            })
            const response: any = await this.pmcTariffDataService.updateAttachment(filesToUpdate);
            if(response.length > 0){
                documents.map(item=>{
                    response.map(resItem=>{
                        if(resItem.pmcTariffDocumentID == item.pmcTariffDocumentID){
                            item.description = resItem.description,
                            item.updateUser = resItem.updateUser,
                            item.updateDate = resItem.updateDate
                        }
                    })
                })
                this.setPMCTariffAttachmentGridData(documents);
                this.cellValueChangeMapAttachment = {};
                this.agGridService.getNodes()?.refreshCells();
                this.backupAttachmentGridData = documents;
                this.uiService.toastService.success('Attachment updated successfully')
            }else {
                this.uiService.toastService.error('There is an error while updating attachment');
            }
        }    
    }

    fetchFileGridData(uploadedFileData){
        const filDataForGrid: any = [];
        uploadedFileData.map((fileData)=>{
            const data = {
                fileName: fileData.fileName,
                description: fileData.description,
                createUser: fileData.createUser,
                createDate: fileData.createDate,
                pmcTariffDocumentID: fileData.pmcTariffDocumentID,
                updateUser: fileData.updateUser,
                updateDate: fileData.updateDate
            }
            filDataForGrid.push(data);
        })
        return filDataForGrid;
    }

    isCellEditable = ()  => {
        return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PMCTariff) ? true : false;
    };
}