import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { CommercialAdvisorXRefDataStore } from '../stores/commercial-advisor-xref-data-store';
import './commercial-advisor-xref.scss';
import { dataStore } from '../../../../common/stores';
import { commercialAdvisorXRefDataStore } from '../stores';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ReactSelect } from 'common/components/select/select';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';


  /* eslint-disable */
interface AddEditCAXrefProps {
    commercialAdvisorXRefDataStore: CommercialAdvisorXRefDataStore;
}


@inject('commercialAdvisorXRefDataStore')
@observer
export class AddEditCAXRef extends Component<AddEditCAXrefProps, any> {
    constructor(props: AddEditCAXrefProps | Readonly<AddEditCAXrefProps>) {
        super(props);
        this.state = {
            search: ''
        };
        dataStore.setModuleName('Add Edit CA Xref')
    }

    componentDidUpdate() {
      const {commercialAdvisorXRefDataStore} = this.props;
      dataStore.showUnsavedWarningOnTabCloseOrReload();
    }

    componentWillUnmount() {
      console.log("unmount" + dataStore.showUnsavedWarningAlert);
    }

    handleClose = () => {
      commercialAdvisorXRefDataStore.onCloseCAXRefModal();
    }
    
    handleRemoveCAXRef = () => {
      commercialAdvisorXRefDataStore.removePMCCommercialAdvisorXref();
    }  
    
    handleShow = () => commercialAdvisorXRefDataStore.isShowModal = true;

    handleSaveCAXRef = async() => 
    {
      commercialAdvisorXRefDataStore.handleSaveCAXRef();
    }

    onSelectTariffNumber = (e) => {
        commercialAdvisorXRefDataStore.setTariffNumberForAddEdit(e);  
    };

    onChangeOperator = (e) => {
        commercialAdvisorXRefDataStore.setOperatorForAddEdit(e);
    };
    
    onChangeRAName = (e) => {
        commercialAdvisorXRefDataStore.setRANameForAddEdit(e.target.value);
    };

    onChangeCaTariffName = (e) => {
        commercialAdvisorXRefDataStore.setCaTariffNameForAddEdit(e.target.value);
    }

    onSelectSystemGroup = (e) => {
        commercialAdvisorXRefDataStore.setSystemGroupForAddEdit(e);
    };

    onSelectDistributionType = (e) => {
        commercialAdvisorXRefDataStore.setDistributionTypeForAddEdit(e);
    };

    onSelectTariffRequestType = (e) => {
        commercialAdvisorXRefDataStore.setTariffRequestTypeForAddEdit(e);
    };

    onSelectTariffRateType = (e) => {
        commercialAdvisorXRefDataStore.setTariffRateTypeForAddEdit(e);
    };

    onSelectCaTariffName = (e) => {
        commercialAdvisorXRefDataStore.setCaTariffNameForAddEdit(e);
    };

    onSelectCaTypeID = (e) => {
        commercialAdvisorXRefDataStore.setCaTariffTypeForAddEdit(e);
    };  

    onSelectCaSystemGroup = (e) => {
        commercialAdvisorXRefDataStore.setCaSystemGroupForAddEdit(e);
    };

    onSelectCaGradeName = (e) => {
        commercialAdvisorXRefDataStore.setCaGradeNameForAddEdit(e);
    };

    onSelectCaFacilityCode = (e) => {
        commercialAdvisorXRefDataStore.setCaFacilityCodeForAddEdit(e);
    };

    onSelectCaVolumeType = (e) => {
        commercialAdvisorXRefDataStore.setCaVolumeTypeForAddEdit(e);
    };
    
    onRemoveCAXRefConfirmationClicked = () => {
      commercialAdvisorXRefDataStore.onRemoveCAXRefConfirmationClicked();
    };
    
    handleCloseCAXRefConfirmationModal = () => {
      commercialAdvisorXRefDataStore.handleCloseCAXRefConfirmationModal();
    } 

    render(): React.ReactNode {
      const { commercialAdvisorXRefDataStore } = this.props;
      
      return (
        <Container fluid>
            {this.renderAddCAXRefScreen()}
        </Container>
      );
    }

    renderAddCAXRefScreen() : React.ReactNode {
      const { commercialAdvisorXRefDataStore } = this.props;
      const headerTitle = commercialAdvisorXRefDataStore.isEditingCAXRef ? 'Edit Commercial Advisor Xref' : 'Add Commercial Advisor Xref';
      return (
    <>
      <Modal className='modal-xl commercialAdvisorXRefBox' show={commercialAdvisorXRefDataStore.isShowModal} onHide={this.handleClose}>
        <Modal.Header className='screenHeader' closeButton>
          <Modal.Title className='screen_title'>{headerTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <Row>
            <Col>
                <label className='standard_label_style'>Tariff Number*</label>
                <ReactSelect
                    className='select'
                    values={commercialAdvisorXRefDataStore.selectedTariffNumberForAddEdit}
                    placeholder="Select"
                    options={commercialAdvisorXRefDataStore.tariffNumberDropdownOptions}
                    onChange={this.onSelectTariffNumber}
                    isMulti={false}
                    isSearchable={true}
                    menuPlacement='bottom'
                />
            </Col>
            <Col>
                <label className="standard_label_style">Tariff Rate Type*</label>
                <ReactSelect
                    className='select'
                    values={commercialAdvisorXRefDataStore.selectedTariffRateTypeForAddEdit}
                    placeholder="Select"
                    options={commercialAdvisorXRefDataStore.tariffRateTypeDropdownOptions}
                    onChange={this.onSelectTariffRateType}
                    isMulti={false}
                    isSearchable={true}
                    menuPlacement='bottom'
                />
            </Col>
            <Col>
                              
                                <label className="standard_label_style">CA Tariff Name*</label>
                                <input
                                className='select input'
                                maxLength={50}
                                type="text"
                                placeholder="CA Tariff Name"
                                onChange={this.onChangeCaTariffName}
                                value={commercialAdvisorXRefDataStore.selectedCaTariffNameForAddEdit}
                            />
                            </Col>
                            <Col>
                                <label className="standard_label_style">CA Tariff Type*</label>
                                <ReactSelect
                                    className='select'
                                    values={commercialAdvisorXRefDataStore.selectedCaTariffTypeForAddEdit}
                                    placeholder="Select"
                                    options={commercialAdvisorXRefDataStore.caTariffTypeDropdownOptions}
                                    onChange={this.onSelectCaTypeID}
                                    isMulti={false}
                                    isSearchable={true}
                                    menuPlacement='bottom'
                                />
                            </Col>
          </Row>
          <Row>
                <Col>
                    <label className='standard_label_style'>Asset Group</label>
                    <ReactSelect
                        className='select'
                        values={commercialAdvisorXRefDataStore.selectedTariffRequestTypeForAddEdit}
                        placeholder=""
                        options={commercialAdvisorXRefDataStore.tariffRequestTypeDropdownOptions}
                        onChange={this.onSelectTariffRequestType}
                        isMulti={false}
                        isSearchable={true}
                        menuPlacement='bottom'
                        disabled={true}    
                    />
                </Col>
                <Col>
                    <label className='standard_label_style'>Distribution</label>
                    <ReactSelect
                        className='select'
                        values={commercialAdvisorXRefDataStore.selectedDistributionTypeForAddEdit}
                        placeholder=""
                        options={commercialAdvisorXRefDataStore.distributionTypeDropdownOptions}
                        onChange={this.onSelectDistributionType}
                        isMulti={false}
                        isSearchable={true}
                        menuPlacement='bottom'
                        disabled={true}
                    />
                </Col>
                <Col>
                                <label className="standard_label_style">CA System Group*</label>
                                <ReactSelect
                                    className='select'
                                    values={commercialAdvisorXRefDataStore.selectedCaSystemGroupForAddEdit}
                                    placeholder="Select"
                                    options={commercialAdvisorXRefDataStore.caSystemGroupDropdownOptions}
                                    onChange={this.onSelectCaSystemGroup}
                                    isMulti={false}
                                    isSearchable={true}
                                    menuPlacement='bottom'
                                />
                            </Col>
                            <Col>
                                <label className="standard_label_style">CA Grade Name*</label>
                                <ReactSelect
                                    className='select'
                                    values={commercialAdvisorXRefDataStore.selectedCaGradeNameForAddEdit}
                                    placeholder="Select"
                                    options={commercialAdvisorXRefDataStore.caGradeNameDropdownOptions}
                                    onChange={this.onSelectCaGradeName}
                                    isMulti={false}
                                    isSearchable={true}
                                    menuPlacement='bottom'
                                />
                            </Col>
            </Row>
            <Row>
                <Col>
                    <label className="standard_label_style">Pipeline System</label>
                    <ReactSelect
                        className='select'
                        values={commercialAdvisorXRefDataStore.selectedSystemGroupForAddEdit}
                        placeholder=""
                        options={commercialAdvisorXRefDataStore.systemGroupDropdownOptions}
                        onChange={this.onSelectSystemGroup}
                        isMulti={false}
                        isSearchable={true}
                        menuPlacement='bottom'
                        disabled={true}
                    />
                </Col>
                <Col>
                    <label className='standard_label_style'>Operator</label>
                    <ReactSelect
                        className='select'
                        values={commercialAdvisorXRefDataStore.selectedOperatorForAddEdit}
                        placeholder=""
                        options={commercialAdvisorXRefDataStore.operatorDropdownOptions}
                        onChange={this.onChangeOperator}
                        isMulti={false}
                        isSearchable={true}
                        menuPlacement='bottom'
                        disabled={true}
                    />
                </Col>
                <Col>
                                <label className="standard_label_style">CA Facility Code*</label>
                                <ReactSelect
                                    className='select'
                                    values={commercialAdvisorXRefDataStore.selectedCaFacilityCodeForAddEdit}
                                    placeholder="Select"
                                    options={commercialAdvisorXRefDataStore.caFacilityCodeDropdownOptions}
                                    onChange={this.onSelectCaFacilityCode}
                                    isMulti={false}
                                    isSearchable={true}
                                    menuPlacement='bottom'
                                />
                            </Col>
                            <Col>
                                <label className="standard_label_style">CA Volume Type*</label>
                                <ReactSelect
                                    className='select'
                                    values={commercialAdvisorXRefDataStore.selectedCaVolumeTypeForAddEdit}
                                    placeholder="Select"
                                    options={commercialAdvisorXRefDataStore.caVolumeTypeDropdownOptions}
                                    onChange={this.onSelectCaVolumeType}
                                    isMulti={false}
                                    isSearchable={true}
                                    menuPlacement='bottom'
                                />
                            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {commercialAdvisorXRefDataStore.isEditingCAXRef && <CustomButton type={CustomButtonType.Submit} onClick={this.handleRemoveCAXRef}>
                Delete
          </CustomButton>}
          {!commercialAdvisorXRefDataStore.isEditingCAXRef && <CustomButton type={CustomButtonType.Submit} onClick={this.handleClose}>
              Close
          </CustomButton>}
          <CustomButton type={CustomButtonType.Submit} onClick={this.handleSaveCAXRef}>
            Save
          </CustomButton>
        </Modal.Footer>
        </Modal>
        <Modal className='modal-lg' show={commercialAdvisorXRefDataStore.isShowRemoveCAXRefConfirmationModal} onHide={this.handleCloseCAXRefConfirmationModal}>
          <Modal.Header className='screenHeader' closeButton>
            <Modal.Title className='screen_title'>Delete CA Xref Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Are you sure you want to delete the selected CA Xref?</div>
          </Modal.Body>
          <Modal.Footer>
            <CustomButton type={CustomButtonType.Submit} onClick={this.handleCloseCAXRefConfirmationModal}>
              No
            </CustomButton>
            <CustomButton type={CustomButtonType.Submit} onClick={this.onRemoveCAXRefConfirmationClicked}>
              Yes
            </CustomButton>
          </Modal.Footer>
        </Modal>
    </>
  );
}
}