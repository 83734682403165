import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _ from 'lodash';
import { commercialAdvisorXRefColDef } from '../components/commercial-advisor-xref-col-def';
import { CommercialAdvisorXRefDataService } from '../services/commercial-advisor-xref-data-service';
import history from '../../../../shared/components/header-component/component/history';
import { AccountStore } from '../../../account/stores/account-store';
import { dataStore } from '../../../../common/stores';
import { RoleBasedActionName } from '../../../../shared/types/enum';
import moment from 'moment';
import { commercialAdvisorXRefDataStore } from '.';

export class CommercialAdvisorXRefDataStore {
    @observable selectedTariffNumber;
    @observable selectedTariffNumberForAddEdit;
    @observable pmcCommercialAdvisiorXrefID = 0;
    @observable commercialAdvisorXRefData: any[] = [];
    @observable tariffNumberDropdownOptions: any[] = [];
    @observable selectedTariffRequestType;
    @observable selectedTariffRequestTypeForAddEdit;
    @observable tariffRequestTypeDropdownOptions: any[] = [];
    @observable selectedRAName;
    @observable selectedRANameForAddEdit;
    @observable raNamesDropdownOptions: any[] = [];
    @observable selectedOperator;
    @observable selectedOperatorForAddEdit;
    @observable operatorDropdownOptions: any[] = [];
    @observable selectedSystemGroup;
    @observable selectedSystemGroupForAddEdit;
    @observable systemGroupDropdownOptions: any[] = [];
    @observable selectedDistributionType;
    @observable selectedDistributionTypeForAddEdit;
    @observable distributionTypeDropdownOptions: any[] = [];
    @observable selectedTariffRateType;
    @observable selectedTariffRateTypeForAddEdit;
    @observable tariffRateTypeDropdownOptions: any[] = [];
    @observable showLoader = false;
    @observable isShowRemoveCAXRefConfirmationModal = false;
    @observable createdUser;
    isRefreshed = false;
    @observable isShowModal = false;
    @observable isEditingCAXRef = false;
    @observable editableCAXRefData: any = {};
    @observable defaultLoggedInUserLocation;

    // Selected Search Dropdown Data
    @observable selectedCaTariffName;
    @observable selectedCaSystemGroup;
    @observable selectedCaFacilityCode;
    @observable selectedCaGradeName;
    @observable selectedCaVolumeType;
    @observable selectedCaTariffType;
    
    // Selected Add/Edit Dropdown Data
    @observable selectedCaTariffNameForAddEdit;
    @observable selectedCaTariffTypeForAddEdit;
    @observable selectedCaSystemGroupForAddEdit;
    @observable selectedCaFacilityCodeForAddEdit;
    @observable selectedCaGradeNameForAddEdit;
    @observable selectedCaVolumeTypeForAddEdit;


    // Dropdown Data
    @observable caTariffNameDropdownOptions: any[] = [];
    @observable caSystemGroupDropdownOptions: any[] = [];
    @observable caFacilityCodeDropdownOptions: any[] = [];
    @observable caTariffTypeDropdownOptions: any[] = [];
    @observable caGradeNameDropdownOptions: any[] = [];
    @observable caVolumeTypeDropdownOptions: any[] = [];
    @observable settingNameOptions: any[] = [];

    tariffNumbersData: any[] = [];
    raNamesData: any[] = [];
    systemGroupData: any[]=[];
    distributionTypeData: any[]=[];
    tariffRequestTypeData: any[] = [];
    tariffRateTypeData: any[] = [];

    constructor(
        public commercialAdvisorXRefDataService: CommercialAdvisorXRefDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
    ) {
        makeAutoObservable(this);
    }

    async init(): Promise<void> {
        this.createdUser = this.accountStore.displayName;
        this.defaultLoggedInUserLocation = this.accountStore.defaultUserLocation;
        this.commercialAdvisorXRefData = [];
        this.selectedTariffNumber = { value: 'All', label: 'All' };
        this.selectedOperator = { value: 'All', label: 'All' };
        this.selectedSystemGroup = { value: 'All', label: 'All' };
        this.selectedDistributionType = { value: 'All', label: 'All' };
        this.selectedTariffRequestType = { value: 'All', label: 'All' };
        this.selectedTariffRateType = { value: 'All', label: 'All' };
        this.selectedCaTariffName = { value: 'All', label: 'All' };
        this.selectedCaSystemGroup = { value: 'All', label: 'All' };
        this.selectedCaFacilityCode = { value: 'All', label: 'All' };
        this.selectedCaGradeName = { value: 'All', label: 'All' };
        this.selectedCaVolumeType = { value: 'All', label: 'All' };
        this.selectedCaTariffType = { value: 'All', label: 'All' };
        await this.getTariffNumberDropdownData();
        await this.getOperatorDropdownData();
        await this.getSystemGroupDropdownData();
        await this.getCommercialAdvisorMiscData();
        await this.getDistributionTypeDropdownData();
        await this.getTariffRequestTypeDropdownData();
        await this.getTariffRateTypeDropdownData();
        await this.getSettingNameOptions(this.defaultLoggedInUserLocation);
        await this.onSearchClick();
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Setting Name Options'))
    async getSettingNameOptions(defaultUserLocation): Promise<void> {
        const settingNameOptions = await this.commercialAdvisorXRefDataService.getSettingNameOptions(defaultUserLocation);
        this.showLoader = false;
        // const a = (settingNameOptions.map(x => {return {settingTypeDesc : x.settingTypeDesc, defaultLocation: a.defaultLocation} }));
        const b = settingNameOptions.filter((x , index, arr) => arr.indexOf(x) === index)
        const options: any = [];
        const optionsData: any = [];
        b.forEach((item) => {
            options.push({ settingTypeDesc: item.settingTypeDesc, defaultLocation: item.defaultLocation })
            optionsData.push({ 'value': item.settingTypeDesc, 'label': item.settingTypeDesc });
        })
        this.setSettingNameOptions(optionsData);
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffNumberDropdownData(): Promise<void> {
        const tariffNumbers = await this.commercialAdvisorXRefDataService.getPMCTariffDetailIDs();
        this.tariffNumbersData = tariffNumbers;
        this.showLoader = false;
        const a = tariffNumbers.map(x => {return { 'value': x, 'label': x}});
        const sortedTariffNumbers =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setTariffNumberDropdownOptions(sortedTariffNumbers);
    }

    @action
    setSettingNameOptions(options){
        this.settingNameOptions = options
    }

    @action
    setTariffNumberDropdownOptions(options){
        this.tariffNumberDropdownOptions = options
    }

    @action
    setTariffNumber(tariffNumber: any): void {
        this.selectedTariffNumber = tariffNumber;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Operator values'))
    async setTariffNumberForAddEdit(tariffNumber: any): Promise<void> {
        this.selectedTariffNumberForAddEdit = tariffNumber;
        const response: any = await this.commercialAdvisorXRefDataService.getPMCCommercialAdvisorXRefSupplementaryData(tariffNumber.label);
        if (response) {
            runInAction(() => {
                this.selectedTariffRequestTypeForAddEdit = { label: response.assetGroup, value: response.assetGroup };
                this.selectedSystemGroupForAddEdit = { label: response.pipelineSystem, value: response.pipelineSystem };
                this.selectedOperatorForAddEdit = { label: response.operator, value: response.operator };
                this.selectedDistributionTypeForAddEdit = { label: response.distribution, value: response.distribution };
            })   
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Operator values'))
    async getOperatorDropdownData(): Promise<void> {
        const operators = await this.commercialAdvisorXRefDataService.getOperators();
        this.showLoader = false;
        const a = operators.map(x => {return { 'value': x, 'label': x}});
        const sortedOperators =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setOperatorDropdownOptions(sortedOperators);
    }

    @action
    setOperatorDropdownOptions(options){
        this.operatorDropdownOptions = options
    }

    @action
    setOperatorForAddEdit(operator: any): void {
        this.selectedOperatorForAddEdit = operator;
    }

    @action
    setOperator(operator: any): void {
        this.selectedOperator = operator;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffRequestTypeDropdownData(): Promise<void> {
        const userJurisdiction = this.accountStore.defaultUserLocation;
        const tariffRequestTypes = await this.commercialAdvisorXRefDataService.getTariffRequestTypes(userJurisdiction);
        this.tariffRequestTypeData = tariffRequestTypes;
        this.showLoader = false;
        const a = tariffRequestTypes.map(x => {return { 'value': x.assetGroupID, 'label': x.name}});
        const sortedTariffRequestTypes =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setTariffRequestTypeDropdownOptions(sortedTariffRequestTypes);
    }

    @action
    setTariffRequestTypeDropdownOptions(options){
        this.tariffRequestTypeDropdownOptions = options
    }

    @action
    setTariffRequestType(tariffRequestType: any): void {
        this.selectedTariffRequestType = tariffRequestType;
    }

    @action
    setTariffRequestTypeForAddEdit(tariffRequestType: any): void {
        this.selectedTariffRequestTypeForAddEdit = tariffRequestType;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Pipeline System'))
    async getSystemGroupDropdownData(): Promise<void> {
        const systemGroups = await this.commercialAdvisorXRefDataService.getSystemGroup();
        this.systemGroupData = systemGroups;
        this.showLoader = false;
        const a = systemGroups.map(x => {return { 'value': x.settingID, 'label': x.valueText}});
        const sortedSystemGroups =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setSystemGroupDropdownOptions(sortedSystemGroups);
    }

    @action
    setSystemGroupDropdownOptions(options){
        this.systemGroupDropdownOptions = options
    }

    getSelectedSystemGroupID = () =>{
        const systemGroupID = !this.selectedSystemGroup || this.selectedSystemGroup.label == '' || this.selectedSystemGroup.label == 'All' ? '' : this.systemGroupData.find(x=>x.valueText===this.selectedSystemGroup.label)?.settingID;
        return systemGroupID;
    }

    getSelectedDistributionID = () =>{
        const distributionID = !this.selectedDistributionType || this.selectedDistributionType.label === '' || this.selectedDistributionType.label === 'All' ? '' : this.distributionTypeData.find(x=>x.valueText===this.selectedDistributionType.label)?.settingID;
        return distributionID;
    }

    @action
    setSystemGroup(systemGroup: any): void {
        this.selectedSystemGroup = systemGroup;
    }

    // @action
    setSystemGroupForAddEdit(systemGroup: any): void {
        this.selectedSystemGroupForAddEdit = systemGroup;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Commercial Advisor Misc Data'))
    async getCommercialAdvisorMiscData(): Promise<void> {
        const caMiscData = await this.commercialAdvisorXRefDataService.getPMCCommercialAdvisiorXrefMiscData();
        const caSystemGroup = await this.commercialAdvisorXRefDataService.getCASystemGroups();
        const caFacilityCode = await this.commercialAdvisorXRefDataService.getPMCFacilityCodes();
        const caGradeName = await this.commercialAdvisorXRefDataService.getCAGradeNames();
        const caVolumeType = await this.commercialAdvisorXRefDataService.getCAVolumeTypes();
        const caTariffType = await this.commercialAdvisorXRefDataService.getCATariffTypes();
        const dropdownData = {
            'caTariffName': caMiscData['caTariffName'] || [],
            'caSystemGroup': caSystemGroup,
            'caFacilityCode': caFacilityCode,
            'caGradeName': caGradeName,
            'caVolumeType': caVolumeType,
            'caTariffType': caTariffType
        }
        this.setCAXRefDropdownOptions(dropdownData);
        this.showLoader = false;
    }

    @action
    setCAXRefDropdownOptions(options){
        this.caTariffNameDropdownOptions = options.caTariffName.map(x => {return { 'value': x, 'label': x}})
        this.caSystemGroupDropdownOptions = options.caSystemGroup.map(x => {return { 'value': x.valueCode, 'label': x.valueText}});
        this.caFacilityCodeDropdownOptions = options.caFacilityCode.map(x => {return { 'value': x.valueCode, 'label': x.valueText}});
        this.caGradeNameDropdownOptions = options.caGradeName.map(x => {return { 'value': x.valueCode, 'label': x.valueText}});
        this.caVolumeTypeDropdownOptions = options.caVolumeType.map(x => {return { 'value': x.valueCode, 'label': x.valueText}});
        this.caTariffTypeDropdownOptions = options.caTariffType.map(x => {return { 'value': x.valueCode, 'label': x.valueText}});
    }



    @action
    setRANameDropdownOptions(options){
        this.raNamesDropdownOptions = options
    }

    @action
    setRAName(raName: any): void {
        this.selectedRAName = raName;
    }

    @action
    setRANameForAddEdit(raName: any): void {
        this.selectedRANameForAddEdit = raName;
    }

    @action
    setCaTariffNameForAddEdit(caTariffName: any): void {
        this.selectedCaTariffNameForAddEdit = caTariffName;
    }

    @action
    setCaSystemGroupForAddEdit(caSystemGroup: any): void {
        this.selectedCaSystemGroupForAddEdit = caSystemGroup;
    }

    @action
    setCaFacilityCodeForAddEdit(caFacilityCode: any): void {
        this.selectedCaFacilityCodeForAddEdit = caFacilityCode;
    }

    @action
    setCaGradeNameForAddEdit(caGradeName: any): void {
        this.selectedCaGradeNameForAddEdit = caGradeName;
    }

    @action
    setCaVolumeTypeForAddEdit(caVolumeType: any): void {
        this.selectedCaVolumeTypeForAddEdit = caVolumeType;
    }

    @action
    setCaTariffTypeForAddEdit(caTariffType: any): void {
        this.selectedCaTariffTypeForAddEdit = caTariffType;
    }

    @action
    setTariffRateTypeForAddEdit(tariffRateType: any): void {
        this.selectedTariffRateTypeForAddEdit = tariffRateType;
    }



    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffRateTypeDropdownData(): Promise<void> {
        const tariffRateTypes = await this.commercialAdvisorXRefDataService.getTariffRateTypes();
        tariffRateTypes.push({'systemName': 'Total','valueCode': 'Total'})
        this.tariffRateTypeData = tariffRateTypes;
        this.showLoader = false;
        const a = tariffRateTypes.map(x => {return { 'value': x.valueCode, 'label': x.systemName}});
        const sortedTariffRateTypes =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setTariffRateTypeDropdownOptions(sortedTariffRateTypes);
    }

    @action
    setTariffRateTypeDropdownOptions(options){
        this.tariffRateTypeDropdownOptions = options
    }

    @action
    setTariffRateType(tariffRateType: any): void {
        this.selectedTariffRateType = tariffRateType;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Distribution Types'))
    async getDistributionTypeDropdownData(): Promise<void> {
        const distributionTypes = await this.commercialAdvisorXRefDataService.getDistributionTypes();
        this.distributionTypeData = distributionTypes;
        this.showLoader = false;
        const a = distributionTypes.map(x => {return { 'value': x.settingID, 'label': x.valueText}});
        const sortedDistributionType =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setDistributionTypeDropdownOptions(sortedDistributionType);
    }

    @action
    setDistributionTypeDropdownOptions(options){
        this.distributionTypeDropdownOptions = options
    }

    @action
    setDistributionType(distributionType: any): void {
        this.selectedDistributionType = distributionType;
    }

    setDistributionTypeForAddEdit(distributionType: any): void {
        this.selectedDistributionTypeForAddEdit = distributionType;
    }

    @action
    setCaTariffName(caTariffName: any): void {
        this.selectedCaTariffName = caTariffName;
    }

    @action
    setCaSystemGroup(caSystemGroup: any): void {
        this.selectedCaSystemGroup = caSystemGroup;
    }

    @action
    setCaFacilityCode(caFacilityCode: any): void {
        this.selectedCaFacilityCode = caFacilityCode;
    }

    @action
    setCaGradeName(caGradeName: any): void {
        this.selectedCaGradeName = caGradeName;
    }

    @action
    setCaVolumeType(caVolumeType: any): void {
        this.selectedCaVolumeType = caVolumeType;
    }

    @action
    setCaTariffType(caTypeID: any): void {
        this.selectedCaTariffType = caTypeID;
    }

    @Loader
    @Catch(() => errorHandler('Data could not be loaded'))
    async onSearchClick(): Promise<void> {
        const tariffNumber = this.selectedTariffNumber && this.selectedTariffNumber.label != 'All' && this.selectedTariffNumber.label != '' ? this.selectedTariffNumber.label : '';
        const systemGroupID = this.getSelectedSystemGroupID();
        const operator = this.selectedOperator && this.selectedOperator.label != '' && this.selectedOperator.label != 'All' ? this.selectedOperator.label : '';
        const distributionID = this.getSelectedDistributionID();
        const assetGroupID = !this.selectedTariffRequestType || this.selectedTariffRequestType.label === '' || this.selectedTariffRequestType.label === 'All' ? '' : this.tariffRequestTypeData.find(x=>x.name===this.selectedTariffRequestType.label)?.assetGroupID;
        const caTariffName = this.selectedCaTariffName && this.selectedCaTariffName.label != '' && this.selectedCaTariffName.label != 'All' ? this.selectedCaTariffName.label : '';
        const caSystemGroup = this.selectedCaSystemGroup && this.selectedCaSystemGroup.label != '' && this.selectedCaSystemGroup.label != 'All' ? this.selectedCaSystemGroup.label : '';
        const caFacilityCode = this.selectedCaFacilityCode && this.selectedCaFacilityCode.label != '' && this.selectedCaFacilityCode.label != 'All' ? this.selectedCaFacilityCode.label : '';
        const caTariffTypeID = this.selectedCaTariffType && this.selectedCaTariffType.label != '' && this.selectedCaTariffType.label != 'All' ? this.selectedCaTariffType.value : '';
        const caGradeName = this.selectedCaGradeName && this.selectedCaGradeName.label != '' && this.selectedCaGradeName.label != 'All' ? this.selectedCaGradeName.label : '';
        const caVolumeType = this.selectedCaVolumeType && this.selectedCaVolumeType.label != '' && this.selectedCaVolumeType.label != 'All' ? this.selectedCaVolumeType.label : '';
        // const tariffRateType = this.selectedTariffRateType && this.selectedTariffRateType.label != '' && this.selectedTariffRateType.label != 'All' ? this.selectedTariffRateType.label : '';
        // const raName = this.selectedRAName && this.selectedRAName.label != '' && this.selectedRAName.label != 'All' ? this.selectedRAName.label : '';
        const response = await this.commercialAdvisorXRefDataService.getCAXrefDetails(tariffNumber, assetGroupID, systemGroupID, operator, distributionID, caTariffName, caSystemGroup, caFacilityCode, caTariffTypeID, caGradeName, caVolumeType);
        if(response) {
            if(response['statusCode'] && response['statusCode'] === 400) {
                this.commercialAdvisorXRefData = [];
                this.showLoader = false;
                this.isRefreshed = false;
                this.uiService.toastService.error('No data found');
            } else {
            runInAction(() => {
                this.showLoader = false;
                const sortedCommercialAdvisorXRefData = _.orderBy(response, [x=>x.tariffNumber], ['asc']);
                this.commercialAdvisorXRefData = sortedCommercialAdvisorXRefData;
                this.isRefreshed = false;
            })
            this.agGridService.getNodes()?.refreshCells();
            dataStore.setShowUnsavedWarningAlert(false);
        }
        }
        this.agGridService.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    resetSearchFilter() {
        this.commercialAdvisorXRefData = [];
        this.selectedTariffNumber = { value: 'All', label: 'All' };
        this.selectedOperator = { value: 'All', label: 'All' };
        this.selectedRAName = { value: 'All', label: 'All' };
        this.selectedSystemGroup = { value: 'All', label: 'All' };
        this.selectedDistributionType = { value: 'All', label: 'All' };
        this.selectedTariffRequestType = { value: 'All', label: 'All' };
        this.selectedTariffRateType = { value: 'All', label: 'All' };
        this.selectedCaTariffName = { value: 'All', label: 'All' };
        this.selectedCaSystemGroup = { value: 'All', label: 'All' };
        this.selectedCaFacilityCode = { value: 'All', label: 'All' };
        this.selectedCaGradeName = { value: 'All', label: 'All' };
        this.selectedCaVolumeType = { value: 'All', label: 'All' };
        this.selectedCaTariffType = { value: 'All', label: 'All' };
    }

    @Loader
    @Catch(() => errorHandler('Data could not be loaded'))
    async onClearSearch(): Promise<void> {
        const response = await this.commercialAdvisorXRefDataService.getCAXrefDetailsWithoutFilter();
        runInAction(() => {
            this.showLoader = false;
            this.resetSearchFilter();
            this.commercialAdvisorXRefData = response;
            this.isRefreshed = false;
        })
        this.agGridService.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    @action
    async onAddNewCAXRef() {
        this.isShowModal = true;      
    }

    @action
    validateCAXRefDetails() {
        const localValidatingErrorBody: any = [];
        let isValidSystemFee = true;
        if(!this.selectedTariffNumberForAddEdit || this.selectedTariffNumberForAddEdit.label === '' || this.selectedTariffNumberForAddEdit.label === 'Select' || !this.selectedTariffNumberForAddEdit.label){
            const message = 'Please select Tariff Number.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        if(!this.selectedOperatorForAddEdit || this.selectedOperatorForAddEdit.label === '' || this.selectedOperatorForAddEdit.label === 'Select' || !this.selectedOperatorForAddEdit.label){
            const message = 'Please select Operator value.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        if(!this.selectedTariffRequestTypeForAddEdit || this.selectedTariffRequestTypeForAddEdit.label === '' || this.selectedTariffRequestTypeForAddEdit.label === 'Select' || !this.selectedTariffRequestTypeForAddEdit.label){
            const message = 'Please select Asset Group.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        if(!this.selectedDistributionTypeForAddEdit || this.selectedDistributionTypeForAddEdit.label === '' || this.selectedDistributionTypeForAddEdit.label === 'Select' || !this.selectedDistributionTypeForAddEdit.label){
            const message = 'Please select Distribution Type.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }

        if(!this.selectedSystemGroupForAddEdit || this.selectedSystemGroupForAddEdit.label === '' || this.selectedSystemGroupForAddEdit.label === 'Select' || !this.selectedSystemGroupForAddEdit.label){
            const message = 'Please select Pipeline System Name.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }

        if(!this.selectedTariffRateTypeForAddEdit || this.selectedTariffRateTypeForAddEdit.label && (this.selectedTariffRateTypeForAddEdit.label === '' || this.selectedTariffRateTypeForAddEdit.label === 'Select' || !this.selectedTariffRateTypeForAddEdit.label)){
            const message = 'Please select Tariff Rate Type.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        if(!this.selectedCaTariffNameForAddEdit){
            const message = 'Please select CA Tariff Name.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        if(!this.selectedCaSystemGroupForAddEdit || this.selectedCaSystemGroupForAddEdit.label === '' || this.selectedCaSystemGroupForAddEdit.label === 'Select' || !this.selectedCaSystemGroupForAddEdit.label){
            const message = 'Please select CA System Group.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        if(!this.selectedCaFacilityCodeForAddEdit || this.selectedCaFacilityCodeForAddEdit.label === '' || this.selectedCaFacilityCodeForAddEdit.label === 'Select' || !this.selectedCaFacilityCodeForAddEdit.label){
            const message = 'Please select CA Facility Code.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        if(!this.selectedCaTariffTypeForAddEdit || this.selectedCaTariffTypeForAddEdit.label === '' || this.selectedCaTariffTypeForAddEdit.label === 'Select' || !this.selectedCaTariffTypeForAddEdit.label){
            const message = 'Please select CA Tariff Type.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        if(!this.selectedCaGradeNameForAddEdit || this.selectedCaGradeNameForAddEdit.label === '' || this.selectedCaGradeNameForAddEdit.label === 'Select' || !this.selectedCaGradeNameForAddEdit.label){
            const message = 'Please select CA Grade Name.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        if(!this.selectedCaVolumeTypeForAddEdit || this.selectedCaVolumeTypeForAddEdit.label === '' || this.selectedCaVolumeTypeForAddEdit.label === 'Select' || !this.selectedCaVolumeTypeForAddEdit.label){
            const message = 'Please select CA Volume Type.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        return {
            isValidSystemFee: isValidSystemFee, 
            localValidatingErrorBody: localValidatingErrorBody
        };
    }

    @action
    setCAXRefValue = (data) => {
        if (data) {
            const systemGroupId = this.systemGroupData.find(x => x.valueText === data.pipelineSystem)?.settingID;
            const distributionId = this.distributionTypeData.find(x=>x.valueText===data.distribution)?.settingID;
            const assetGroupId = this.tariffRequestTypeData.find(x => x.valueText === data.assetGroup)?.settingID;
            this.pmcCommercialAdvisiorXrefID = data.pmcCommercialAdvisiorXrefID;
            this.selectedSystemGroupForAddEdit = {label:data.pipelineSystem,value:systemGroupId}
            this.selectedRANameForAddEdit = data.raName;
            this.selectedTariffNumberForAddEdit = {label:data.tariffNumber,value:data.tariffNumber};
            this.selectedOperatorForAddEdit = {label:data.operator,value:data.operator};
            this.selectedTariffRateTypeForAddEdit = {label:data.tariffRateType,value:data.tariffRateType};
            this.selectedTariffRequestTypeForAddEdit = { value: assetGroupId, label: data.assetGroup };
            this.selectedDistributionTypeForAddEdit = { value: distributionId, label: data.distribution };
            this.selectedCaTariffNameForAddEdit = data.caTariffName;
            this.selectedCaSystemGroupForAddEdit = {label:data.caSystemGroup,value:data.caSystemGroup};
            this.selectedCaFacilityCodeForAddEdit = {label:data.caFacilityCode,value:data.caFacilityCode};
            this.selectedCaTariffTypeForAddEdit = {label:data.caTariffTypeName,value:data.caTariffTypeID};
            this.selectedCaGradeNameForAddEdit = {label:data.caGradeName,value:data.caGradeName};
            this.selectedCaVolumeTypeForAddEdit = {label:data.caVolumeType,value:data.caVolumeType};
        } else {
            this.pmcCommercialAdvisiorXrefID = 0;
            this.selectedSystemGroupForAddEdit = { value: 'Select', label: 'Select' };
            this.selectedRANameForAddEdit = '';
            this.selectedTariffNumberForAddEdit = { value: 'Select', label: 'Select' };
            this.selectedOperatorForAddEdit = { value: 'Select', label: 'Select' };
            this.selectedTariffRateTypeForAddEdit = { value: 'Select', label: 'Select' };
            this.selectedTariffRequestTypeForAddEdit = { value: 'Select', label: 'Select' };
            this.selectedDistributionTypeForAddEdit = { value: 'Select', label: 'Select' };
            this.selectedCaTariffNameForAddEdit = '';
            this.selectedCaSystemGroupForAddEdit = { value: 'Select', label: 'Select' };
            this.selectedCaFacilityCodeForAddEdit = { value: 'Select', label: 'Select' };
            this.selectedCaTariffTypeForAddEdit = { value: 'Select', label: 'Select' };
            this.selectedCaGradeNameForAddEdit = { value: 'Select', label: 'Select' };
            this.selectedCaVolumeTypeForAddEdit = { value: 'Select', label: 'Select' };
        }
    }

    @action
    async handleSaveCAXRef() : Promise<void> {
        const {isValidSystemFee, localValidatingErrorBody} = this.validateCAXRefDetails();
        if(isValidSystemFee){
            const reqBody = {
                'pmcCommercialAdvisiorXrefID': this.pmcCommercialAdvisiorXrefID,
                'tariffNumber': this.selectedTariffNumberForAddEdit.label.toString(),
                'caTariffName': this.selectedCaTariffNameForAddEdit,
                'caSystemGroup': this.selectedCaSystemGroupForAddEdit.label.toString(),
                'caFacilityCode': this.selectedCaFacilityCodeForAddEdit.label.toString(),
                'caTariffTypeID': Number(this.selectedCaTariffTypeForAddEdit.value),
                'caGradeName': this.selectedCaGradeNameForAddEdit.label.toString(),
                'caVolumeType': this.selectedCaVolumeTypeForAddEdit.label.toString(),
                'tariffRateType': this.selectedTariffRateTypeForAddEdit.label,
                'createUser': this.isEditingCAXRef ? this.editableCAXRefData.createUser : this.accountStore.displayName,
                'createDate': this.isEditingCAXRef ? this.editableCAXRefData.createDate : moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss[Z]'),
                'updateUser': this.accountStore.displayName,
                'updateDate': moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss[Z]'),
                'isActive': true,
                'caTariffTypeName': this.selectedCaTariffTypeForAddEdit.label.toString(),
                'assetGroup': this.selectedTariffRequestTypeForAddEdit.label.toString(),
                'pipelineSystem': this.selectedSystemGroupForAddEdit.label.toString(),
                'operator': this.selectedOperatorForAddEdit.label.toString(),
                'distribution': this.selectedDistributionTypeForAddEdit.label.toString(),
            };
            const response: any[] = await this.commercialAdvisorXRefDataService.savePMCCommercialAdvisorXRef(reqBody);
            if(response){

                if(response['statusCode'] && response['statusCode'] === 400) {
                    if(response['value'] && response['value'].includes('There is already a record for the Tariff number')) {
                        this.uiService.toastService.error('There is already a record for the Tariff number');
                        return;
                    }
                    this.uiService.toastService.error(response['value']);
                    return;
                }

                // Refresh CA XRef Misc Data
                await this.getCommercialAdvisorMiscData();

                this.uiService.toastService.success('Commercial Advisor Xref details saved successfully');
                this.setCAXRefValue(null);
                commercialAdvisorXRefDataStore.isShowModal = false;
                this.onSearchClick();
            }else {
                this.uiService.toastService.error('There is an error while saving Commercial Advisor Xref details');
            }
        }else{
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        }
    }

    @action
    async removePMCCommercialAdvisorXref() {
        this.isShowRemoveCAXRefConfirmationModal = true;
    }

    @action
    handleCloseCAXRefConfirmationModal() {
        this.isShowRemoveCAXRefConfirmationModal = false;
    }

    @action
    async onRemoveCAXRefConfirmationClicked(): Promise<void> {
        const reqBody = [this.pmcCommercialAdvisiorXrefID];
        const response: any[] = await this.commercialAdvisorXRefDataService.removePMCCommercialAdvisorXRef(reqBody);
        if(response){
            this.uiService.toastService.success('Commerical Advisor Xref details deleted successfully');
            this.selectedSystemGroupForAddEdit = { value: 'Select', label: 'Select' };
            this.isShowRemoveCAXRefConfirmationModal = false;
            commercialAdvisorXRefDataStore.isShowModal = false;
            this.setCAXRefValue(null);
            this.onSearchClick();
        }else {
            this.uiService.toastService.error('There is an error while deleting Commerical Advisor Xref details');
        }
    }

    @Loader
    @action
    async onClickEditRAMapping(data) {
        this.editableCAXRefData = data;
        this.setCAXRefValue(data);
        this.isEditingCAXRef = true;
        this.isShowModal = true;
    }

    @action
    async onCloseCAXRefModal() {
        this.isShowModal = false;
        this.editableCAXRefData = {};
        this.setCAXRefValue(null);
        this.isEditingCAXRef = false;
    }

    getColDef() {
        return commercialAdvisorXRefColDef;
    }

    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccess(RoleBasedActionName.Save);
    };
}