import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _ from 'lodash';
import { pmcDashboardColDef } from '../components/pmc-dashboard-col-def';
import { PMCDashboardDataService } from '../services/pmc-dashboard-data-service';
import { PMCDashboardResponse } from '../model/model';
import history from '../../../../shared/components/header-component/component/history';
import { PMCDashboardMessages } from '../constants/enums';
import { AccountStore } from '../../../account/stores/account-store';
import { pmcDashboardDataStore } from '.';
import { dataStore } from '../../../../common/stores';
import { RoleBasedActionName } from '../../../../shared/types/enum';
import { pmcTariffDataStore } from 'features/tariff-management/pmc-tariff/stores';
import moment from 'moment';

export class PMCDashboardDataStore {
    @observable pmcDashboardData: PMCDashboardResponse[] = [];
    @observable showLoader = false;
    @observable createdUser;
    isRefreshed = false;
    @observable showModal = false;
    @observable selectedTariffNumber;
    @observable tariffNumberDropdownOptions: any[] = [];
    @observable selectedTariffRequestType;
    @observable tariffRequestTypeDropdownOptions: any[] = [];
    @observable selectedExternalSystem;
    @observable externalSystemDropdownOptions: any[] = [];
    @observable selectedLSD;
    @observable enteredOperator;
    @observable operatorDropdownOptions: any[] = [];
    @observable selectedDestination;
    @observable selectedEffectiveStartDate = new Date(moment(new Date).startOf('month').format('YYYY-MM-DDTHH:mm:ss'));
    @observable selectedEffectiveEndDate= new Date(moment(new Date).endOf('month').format('YYYY-MM-DDTHH:mm:ss'));
    @observable selectedProduct;
    @observable productDropdownOptions: any[] = [];
    @observable selectedProductType;
    @observable productTypeDropdownOptions: any[] = [];
    @observable selectedTariffApplication;
    @observable tariffApplicationDropdownOptions: any[] = [];
    @observable selectedUnit;
    @observable unitDropdownOptions: any[]= [];
    @observable selectedTariffStatus;
    @observable tariffStatusDropdownOptions: any[]= [];
    @observable selectedCurrency;
    @observable currencyDropdownOptions: any[] = [];
    @observable selectedRegulator;
    @observable regulatorDropdownOptions: any[] = [];
    @observable selectedFacilityCode;
    @observable facilityCodeDropdownOptions: any[] = [];
    @observable lsdDropdownOptions: any[] = [];
    @observable destinationDropdownOptions: any[] = [];
    @observable selectedSystemGroup;
    @observable systemGroupDropdownOptions: any[] = [];
    @observable selectedDistributionType;
    @observable distributionTypeDropdownOptions: any[] = [];
    @observable systemGroupDropdownOption: any[] = [];
    @observable selectedPipelineGroup;
    @observable pipelineGroupDropdownOptions: any[] = [];
    @observable userNamesDropdownOptions: any[] = [];
    @observable selectedCreatedUserName;
    @observable selectedUpdatedUserName;

    tariffNumbersData: any[]=[];
    systemGroupData: any[]=[];
    pipelineGroupData: any[]=[];
    distributionTypeData: any[]=[];
    tariffRequestTypeData: any[]=[];
    facilities: any[]=[];
    facilityCodeData: any[]=[];
    lsdData: any[]=[];
    productsData: any[]=[];
    unitData: any[]=[];
    currencyData: any[]=[];
    regulatorData: any[]=[];
    externalSystemData: any[]=[];
    productTypeData: any[]=[];
    tariffApplicationData: any[]=[];
    shrinkageData: any[]=[];
    lossAllowanceData: any[] = [];
    tariffStatusData: any[]=[];
    
    constructor(
        public pmcDashboardDataService: PMCDashboardDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
    ) {
        makeAutoObservable(this);
    }

    monthNames = [{ 'label': 'Jan', 'value': 'January' }, { 'label': 'Feb', 'value': 'February' }, { 'label': 'Mar', 'value': 'March' }, { 'label': 'Apr', 'value': 'April' }, { 'label': 'May', 'value': 'May' }, { 'label': 'Jun', 'value': 'June' }, { 'label': 'Jul', 'value': 'July' }, { 'label': 'Aug', 'value': 'August' }, { 'label': 'Sep', 'value': 'September' }, { 'label': 'Oct', 'value': 'October' }, { 'label': 'Nov', 'value': 'November' }, { 'label': 'Dec', 'value': 'December' }];

    async init(): Promise<void> {
        this.createdUser = this.accountStore.displayName;
        this.pmcDashboardData = [];
        this.resetSearchFilter();
        dataStore.setShowUnsavedWarningAlert(false);
        this.getTariffNumberDropdownData();
        this.getTariffRequestTypeDropdownData();
        this.getProductDropdownData();
        this.getExternalSystemDropdownData();
        this.getProductTypeDropdownData();
        this.getTariffApplicationDropdownData();
        this.getUnitDropdownData();
        this.getCurrencyDropdownData();
        this.getRegulatorDropdownData();
        this.getFacilityCodeDropdownData();
        this.getLSDDropdownData();
        this.getDestinationDropdownData();
        this.getOperatorDropdownData();
        this.getShrinkageDropdownData();
        this.getLossAllowanceDropdownData();
        this.getUserNamesDropdownData();
        this.getPipelineGroupDropdownData();
        this.getTariffStatusDropdownData();
        this.getSystemGroupDropdownData();
        this.getDistributionTypeDropdownData();
        this.onSearchClick();
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffNumberDropdownData(): Promise<void> {
        const tariffNumbers = await this.pmcDashboardDataService.getPMCTariffDetailIDs();
        this.tariffNumbersData = tariffNumbers;
        this.showLoader = false;
        const a = tariffNumbers.map(x => {return { 'value': x, 'label': x}});
        const sortedTariffNumbers =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setTariffNumberDropdownOptions(sortedTariffNumbers);
    }

    @action
    setTariffNumberDropdownOptions(options){
        this.tariffNumberDropdownOptions = options
    }

    @action
    setTariffNumber(tariffNumber: any): void {
        this.selectedTariffNumber = tariffNumber;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffRequestTypeDropdownData(): Promise<void> {
        const userJurisdiction = this.accountStore.defaultUserLocation;
        const tariffRequestTypes = await this.pmcDashboardDataService.getTariffRequestTypes(userJurisdiction);
        this.tariffRequestTypeData = tariffRequestTypes;
        this.showLoader = false;
        const a = tariffRequestTypes.map(x => {return { 'value': x.assetGroupID, 'label': x.name}});
        const sortedTariffRequestTypes =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setTariffRequestTypeDropdownOptions(sortedTariffRequestTypes);
    }

    @action
    setTariffRequestTypeDropdownOptions(options){
        this.tariffRequestTypeDropdownOptions = options
    }

    @action
    setTariffRequestType(tariffRequestType: any): void {
        this.selectedTariffRequestType = tariffRequestType;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Products'))
    async getProductDropdownData(): Promise<void> {
        const products = await this.pmcDashboardDataService.getProducts();
        this.productsData = products;
        this.showLoader = false;
        const a = products.map(x => {return { 'value': x.settingID, 'label': x.valueText}});
        const sortedProducts =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setProductDropdownOptions(sortedProducts);
    }

    @action
    setProductDropdownOptions(options){
        this.productDropdownOptions = options
    }

    @action
    setProduct(product: any): void {
        this.selectedProduct = product;
    }

    getSelectedProductID(){
        const productID = !this.selectedProduct || this.selectedProduct == '' ? 0 : this.selectedProduct.label == ' ' ? 0 : this.systemGroupData.find(x=>x.valueText===this.selectedSystemGroup.label)?.settingID;
        return productID;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Pipeline System'))
    async getSystemGroupDropdownData(): Promise<void> {
        const systemGroups = await this.pmcDashboardDataService.getSystemGroup();
        this.systemGroupData = systemGroups;
        this.showLoader = false;
        const a = systemGroups.map(x => {return { 'value': x.settingID, 'label': x.valueText}});
        const sortedSystemGroups =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setSystemGroupDropdownOptions(sortedSystemGroups);
    }

    @action
    setSystemGroupDropdownOptions(options){
        this.systemGroupDropdownOptions = options
    }

    getSelectedSystemGroupID = () =>{
        const systemGroupID = !this.selectedSystemGroup || this.selectedSystemGroup == '' ? 0 : this.selectedSystemGroup.label == ' ' ? 0 : this.systemGroupData.find(x=>x.valueText===this.selectedSystemGroup.label)?.settingID;
        return systemGroupID;
    }

    getSelectedPipelineID = () =>{
        const pipelineID = !this.selectedPipelineGroup || this.selectedPipelineGroup === '' ? 0 : this.selectedPipelineGroup.label == ' ' ? 0 : this.pipelineGroupData.find(x=>x.valueText===this.selectedPipelineGroup.label)?.settingID;
        return pipelineID;
    }

    getSelectedDistributionID = () =>{
        const distributionID = !this.selectedDistributionType || this.selectedDistributionType === '' ? 0 : this.selectedDistributionType.label == ' ' ? 0 : this.distributionTypeData.find(x=>x.valueText===this.selectedDistributionType.label)?.settingID;
        return distributionID;
    }

    @action
    setSystemGroup(systemGroup: any): void {
        this.selectedSystemGroup = systemGroup;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Pipeline Region'))
    async getPipelineGroupDropdownData(): Promise<void> {
        const pipelineGroups = await this.pmcDashboardDataService.getPipelineGroup();
        this.pipelineGroupData = pipelineGroups;
        this.showLoader = false;
        const a = pipelineGroups.map(x => {return { 'value': x.settingID, 'label': x.valueText}});
        const sortedPipelines =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setPipelineGroupDropdownOptions(sortedPipelines);
    }

    @action
    setPipelineGroupDropdownOptions(options){
        this.pipelineGroupDropdownOptions = options
    }

    @action
    setPipelineGroup(pipelineGroup: any): void {
        this.selectedPipelineGroup = pipelineGroup;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Tariff Status'))
    async getTariffStatusDropdownData(): Promise<void> {
        const tariffStatus = await this.pmcDashboardDataService.getTariffStatus();
        this.tariffStatusData = tariffStatus;
        this.showLoader = false;
        const a = tariffStatus.map(x => {return { 'value': x.key, 'label': x.value}});
        const sortedTariffStatus =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setTariffStatusDropdownOptions(sortedTariffStatus);
    }

    @action
    setTariffStatusDropdownOptions(options){
        this.tariffStatusDropdownOptions = options
    }

    @action
    setTariffStatus(tariffStatus: any): void {
        this.selectedTariffStatus = tariffStatus;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Distribution Types'))
    async getDistributionTypeDropdownData(): Promise<void> {
        const distributionTypes = await this.pmcDashboardDataService.getDistributionTypes();
        this.distributionTypeData = distributionTypes;
        this.showLoader = false;
        const a = distributionTypes.map(x => {return { 'value': x.settingID, 'label': x.valueText}});
        const sortedDistributionType =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setDistributionTypeDropdownOptions(sortedDistributionType);
    }

    @action
    setDistributionTypeDropdownOptions(options){
        this.distributionTypeDropdownOptions = options
    }

    @action
    setDistributionType(distributionType: any): void {
        this.selectedDistributionType = distributionType;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching External Systems'))
    async getExternalSystemDropdownData(): Promise<void> {
        const externalSystems = await this.pmcDashboardDataService.getExternalSystems();
        this.externalSystemData = externalSystems;
        this.showLoader = false;
        const a = externalSystems.map(x => {return { 'value': x.settingID, 'label': x.valueText}});
        const sortedExternalSystems =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setExternalSystemDropdownOptions(sortedExternalSystems);
    }

    @action
    setExternalSystemDropdownOptions(options){
        this.externalSystemDropdownOptions = options;
    }

    @action
    setExternalSystem(externalSystem: any): void {
        this.selectedExternalSystem = externalSystem;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Product Types'))
    async getProductTypeDropdownData(): Promise<void> {
        const productTypes = await this.pmcDashboardDataService.getProductTypes();
        this.productTypeData = productTypes;
        this.showLoader = false;
        const a = productTypes.map(x => {return { 'value': x.settingID, 'label': x.valueText}});
        const sortedProductTypes =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setProductTypeDropdownOptions(sortedProductTypes);
    }

    @action
    setProductTypeDropdownOptions(options){
        this.productTypeDropdownOptions = options
    }

    @action
    setProductType(productType: any): void {
        this.selectedProductType = productType;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching User Names values'))
    async getUserNamesDropdownData(): Promise<void> {
        const userJurisdiction = this.accountStore.defaultUserLocation;
        const userNames = await this.pmcDashboardDataService.getUserNames(userJurisdiction);
        this.showLoader = false;
        const a = userNames.map(x => {return { 'value': x.securityUserID, 'label': x.name}});
        const sortedValues =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setUserNamesDropdownOptions(sortedValues);
    }

    @action
    setUserNamesDropdownOptions(options){
        this.userNamesDropdownOptions = options
    }

    @action
    setCreatedUserName(userName: any): void {
        this.selectedCreatedUserName = userName;
    }

    @action
    setUpdatedUserName(userName: any): void {
        this.selectedUpdatedUserName = userName;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Tariff Application'))
    async getTariffApplicationDropdownData(): Promise<void> {
        const tariffApplications = await this.pmcDashboardDataService.getTariffApplications();
        this.tariffApplicationData = tariffApplications;
        this.showLoader = false;
        const a = tariffApplications.map(x => {return { 'value': x.settingID, 'label': x.valueText}});
        const sortedTariffApplications =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setTariffApplicationDropdownOptions(sortedTariffApplications);
    }

    @action
    setTariffApplicationDropdownOptions(options){
        this.tariffApplicationDropdownOptions = options
    }

    @action
    setTariffApplication(tariffApplication: any): void {
        this.selectedTariffApplication = tariffApplication;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching UOM values'))
    async getUnitDropdownData(): Promise<void> {
        const units = await this.pmcDashboardDataService.getUnits();
        this.unitData = units;
        this.showLoader = false;
        const a = units.map(x => {return { 'value': x.settingID, 'label': x.valueText}});
        const sortedValues =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setUnitDropdownOptions(sortedValues);
    }

    @action
    setUnitDropdownOptions(options){
        this.unitDropdownOptions = options
    }

    @action
    setUnit(unit: any): void {
        this.selectedUnit = unit;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Regulator values'))
    async getRegulatorDropdownData(): Promise<void> {
        const regulatores = await this.pmcDashboardDataService.getRegulator();
        this.regulatorData = regulatores;
        this.showLoader = false;
        const a = regulatores.map(x => {return { 'value': x.settingID, 'label': x.valueText}});
        const sortedValues =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setRegulatorDropdownOptions(sortedValues);
    }

    @action
    setRegulatorDropdownOptions(options){
        this.regulatorDropdownOptions = options
    }

    @action
    setRegulator(regulator: any): void {
        this.selectedRegulator = regulator;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Currency values'))
    async getCurrencyDropdownData(): Promise<void> {
        const currencies = await this.pmcDashboardDataService.getCurrency();
        this.currencyData = currencies;
        this.showLoader = false;
        const a = currencies.map(x => {return { 'value': x.settingID, 'label': x.valueText}});
        const sortedValues =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setCurrencyDropdownOptions(sortedValues);
    }

    @action
    setCurrencyDropdownOptions(options){
        this.currencyDropdownOptions = options
    }

    @action
    setCurrency(currency: any): void {
        this.selectedCurrency = currency;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Facility code values'))
    async getFacilityCodeDropdownData(): Promise<void> {
        const facilityCodes = await this.pmcDashboardDataService.getFacilityCodes();
        this.facilityCodeData = facilityCodes;
        this.showLoader = false;
        const a = facilityCodes.map(x => {return { 'value': x.settingID, 'label': x.valueText}});
        const sortedValues =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setFacilityCodeDropdownOptions(sortedValues);
    }

    @action
    setFacilityCodeDropdownOptions(options){
        this.facilityCodeDropdownOptions = options
    }

    @action
    setFacilityCode(facilityCode: any): void {
        this.selectedFacilityCode = facilityCode;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching LSD values'))
    async getLSDDropdownData(): Promise<void> {
        const lsdValues = await this.pmcDashboardDataService.getLSD();
        this.lsdData = lsdValues;
        this.showLoader = false;
        const a = lsdValues.map(x => {return { 'value': x.settingID, 'label': x.valueText}});
        const sortedValues =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setLSDDropdownOptions(sortedValues);
    }

    @action
    setLSDDropdownOptions(options){
        this.lsdDropdownOptions = options
    }

    @action
    setLSD(lsd: any): void {
        this.selectedLSD = lsd;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Destination values'))
    async getDestinationDropdownData(): Promise<void> {
        const destinations = await this.pmcDashboardDataService.getDestination();
        this.showLoader = false;
        const a = destinations.map(x => {return { 'value': x.settingID, 'label': x.valueText}});
        const sortedDestinations =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setDestinationDropdownOptions(sortedDestinations);
    }

    @action
    setDestinationDropdownOptions(options){
        this.destinationDropdownOptions = options
    }

    @action
    setDestination(destination: any): void {
        this.selectedDestination = destination;
    }

    @action
    setEffectiveStartDate(effectiveStartDate: Date): void {
        this.selectedEffectiveStartDate = effectiveStartDate;
      
    }

    @action
    setEffectiveEndDate(effectiveEndDate: Date): void {
        // this.selectedEffectiveEndDate =effectiveEndDate? new Date(moment(effectiveEndDate).endOf('month').format('YYYY-MM-DDTHH:mm:ss')) :null;
         this.selectedEffectiveEndDate = effectiveEndDate;
         if( this.selectedEffectiveEndDate != null)
         {
            this.selectedEffectiveEndDate =new Date(moment(this.selectedEffectiveEndDate).endOf('month').format('YYYY-MM-DDTHH:mm:ss'))
         }
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Operator values'))
    async getOperatorDropdownData(): Promise<void> {
        const operators = await this.pmcDashboardDataService.getOperators();
        this.showLoader = false;
        const a = operators.map(x => {return { 'value': x, 'label': x}});
        const sortedOperators =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setOperatorDropdownOptions(sortedOperators);
    }

    @action
    setOperatorDropdownOptions(options){
        this.operatorDropdownOptions = options
    }

    @action
    setOperator(operator: any): void {
        this.enteredOperator = operator;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Shrinkage values'))
    async getShrinkageDropdownData(): Promise<void> {
        const shrinkageValues = await this.pmcDashboardDataService.getPMCShrinkage();
        this.shrinkageData = shrinkageValues;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Loss Allowance values'))
    async getLossAllowanceDropdownData(): Promise<void> {
        const lossAllowanceValues = await this.pmcDashboardDataService.getPMCLossAllwance();
        this.lossAllowanceData = lossAllowanceValues;
    }

    @action
    getDashboardFilterReqBody(){
        const reqBody: any = [];
        if(this.selectedTariffNumber && this.selectedTariffNumber.label !== 'All' && this.selectedTariffNumber.label !== ' '){
            reqBody.push({'propertyName': 'TARIFFNO', 'value': this.selectedTariffNumber.label.toString() })
        }
        if(this.selectedSystemGroup && this.selectedSystemGroup.label !== 'All' && this.selectedSystemGroup.label !== ' '){
            const systemGroupId = this.systemGroupData.find(x=>x.valueText===this.selectedSystemGroup.label)?.settingID;
            reqBody.push({'propertyName': 'SYSTEM', 'value': systemGroupId.toString() })
        }
        if(this.selectedPipelineGroup && this.selectedPipelineGroup.label !== 'All' && this.selectedPipelineGroup.label !== ' '){
            const pipelineID = this.pipelineGroupData.find(x=>x.valueText===this.selectedPipelineGroup.label)?.settingID;
            reqBody.push({'propertyName': 'REGION', 'value': pipelineID.toString() })
        }
        if(this.selectedDistributionType && this.selectedDistributionType.label !== 'All' && this.selectedDistributionType.label !== ' '){
            const distributionID = this.distributionTypeData.find(x=>x.valueText===this.selectedDistributionType.label)?.settingID;
            reqBody.push({'propertyName': 'DISTRIBUTION', 'value': distributionID.toString() })
        }
        if(this.selectedTariffRequestType && this.selectedTariffRequestType.label !== 'All' && this.selectedTariffRequestType.label !== ' '){
            const tariffRequestTypeId = this.tariffRequestTypeData.find(x=>x.name===this.selectedTariffRequestType.label)?.assetGroupID;
            reqBody.push({'propertyName': 'ASSET', 'value': tariffRequestTypeId.toString() })
        }
        if(this.enteredOperator && this.enteredOperator.label !== 'All' && this.enteredOperator.label !== ' '){
            reqBody.push({'propertyName': 'OPERATOR', 'value': this.enteredOperator.label })
        }
        if(this.selectedFacilityCode && this.selectedFacilityCode.label !== 'All' && this.selectedFacilityCode.label !== ' '){
            const facilityId = this.facilityCodeData.find(x=>x.valueText===this.selectedFacilityCode.label)?.settingID;
            reqBody.push({'propertyName': 'FACILITY', 'value': facilityId.toString() })
        }
        if(this.selectedLSD && this.selectedLSD.label !== 'All' && this.selectedLSD.label !== ' '){
            reqBody.push({'propertyName': 'LSD', 'value': this.selectedLSD.label.toString() })
        }
        if(this.selectedDestination && this.selectedDestination.label !== 'All' && this.selectedDestination.label !== ' '){
            reqBody.push({'propertyName': 'DESTINATION', 'value': this.selectedDestination.label.toString() })
        }
        if(this.selectedTariffApplication && this.selectedTariffApplication.label !== 'All' && this.selectedTariffApplication.label !== ' '){
            const applicationId = this.tariffApplicationData.find(x=>x.valueText===this.selectedTariffApplication.label)?.settingID;
            reqBody.push({'propertyName': 'APPLICATION', 'value': applicationId.toString() })
        }
        if(this.selectedExternalSystem && this.selectedExternalSystem.label !== 'All' && this.selectedExternalSystem.label !== ' '){
            const externalSystemId = this.externalSystemData.find(x=>x.valueText===this.selectedExternalSystem.label)?.settingID;
            reqBody.push({'propertyName': 'EXTERNAL', 'value': externalSystemId.toString() })
        }
        if(this.selectedProductType && this.selectedProductType.label !== 'All' && this.selectedProductType.label !== ' '){
            const productTypeId = this.productTypeData.find(x=>x.valueText===this.selectedProductType.label)?.settingID;
            reqBody.push({'propertyName': 'PRODUCTTYPE', 'value': productTypeId.toString() })
        }
        if(this.selectedProduct && this.selectedProduct.label !== 'All' && this.selectedProduct.label !== ' '){
            const productId = this.productsData.find(x=>x.valueText===this.selectedProduct.label)?.settingID;
            reqBody.push({'propertyName': 'PRODUCT', 'value': productId.toString() })
        }
        if(this.selectedCreatedUserName && this.selectedCreatedUserName.label !== 'All' && this.selectedCreatedUserName.label !== ' '){
            reqBody.push({'propertyName': 'CREATED', 'value': this.selectedCreatedUserName.label.toString() })
        }
        if(this.selectedUpdatedUserName && this.selectedUpdatedUserName.label !== 'All' && this.selectedUpdatedUserName.label !== ' '){
            reqBody.push({'propertyName': 'UPDATED', 'value': this.selectedUpdatedUserName.label.toString() })
        }
        if(this.selectedUnit && this.selectedUnit.label !== 'All' && this.selectedUnit.label !== ' '){
            const unitId = this.unitData.find(x=>x.valueText===this.selectedUnit.label)?.settingID;
            reqBody.push({'propertyName': 'UNIT', 'value': unitId.toString() })
        }
        if(this.selectedCurrency && this.selectedCurrency.label !== 'All' && this.selectedCurrency.label !== ' '){
            const currencyId = this.currencyData.find(x=>x.valueText===this.selectedCurrency.label)?.settingID;
            reqBody.push({'propertyName': 'CURRENCY', 'value': currencyId.toString() })
        }
        if(this.selectedRegulator && this.selectedRegulator.label !== 'All' && this.selectedRegulator.label !== ' '){
            const regulatorId = this.regulatorData.find(x=>x.valueText===this.selectedRegulator.label)?.settingID;
            reqBody.push({'propertyName': 'REGULATOR', 'value': regulatorId.toString() })
        }
        if(this.selectedEffectiveStartDate){
            reqBody.push({'propertyName': 'STARTDATE', 'value': new Date(moment(this.selectedEffectiveStartDate).format('YYYY-MM-DD'))})
        }
        if(this.selectedEffectiveEndDate){
            reqBody.push({'propertyName': 'ENDDATE', 'value': new Date(moment(this.selectedEffectiveEndDate).format('YYYY-MM-DD'))})
        }
        if(this.selectedTariffStatus && this.selectedTariffStatus.label !== 'All' && this.selectedTariffStatus.label !== ' '){
            reqBody.push({'propertyName': 'PMCTARIFFDETAILSTATUS', 'value': this.selectedTariffStatus.label.toString() })
        }
        return reqBody;
    }

    @Loader
    @Catch(() => errorHandler(PMCDashboardMessages.FETCH_ERROR_MESSAGE))
    async onSearchClick(): Promise<void> {
        const localValidatingErrorBody: any = [];
        let isValidSearch = true;
        if(this.selectedEffectiveStartDate && this.selectedEffectiveEndDate ? moment(this.selectedEffectiveEndDate).isBefore(this.selectedEffectiveStartDate):false){
            const message = 'End Date can not be before Start Date. Please select correct End Date.';
            localValidatingErrorBody.push(message);
            isValidSearch = false;
        }
        if (isValidSearch) {
            const reqBody = this.getDashboardFilterReqBody();
            const response = await this.pmcDashboardDataService.getPMCDashboardDetails(reqBody);
            const sortedArrRes =  _.orderBy(response,[item =>item.tariffNumber],['asc']);
            runInAction(() => {
                this.showLoader = false;
                this.pmcDashboardData = sortedArrRes;
                this.isRefreshed = false;
            })
            this.agGridService.getNodes()?.refreshCells();  
            dataStore.setShowUnsavedWarningAlert(false);
        }
        else {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        }
    }

    resetSearchFilter() {
        this.pmcDashboardData = [];
        this.selectedEffectiveStartDate = new Date(moment(new Date).startOf('month').format('YYYY-MM-DDTHH:mm:ss'));
        this.selectedEffectiveEndDate = new Date(moment(new Date).endOf('month').format('YYYY-MM-DDTHH:mm:ss'));
        this.selectedTariffNumber = { value: 'All', label: 'All' };
        this.selectedCreatedUserName = { value: 'All', label: 'All' };
        this.selectedUpdatedUserName = { value: 'All', label: 'All' };
        this.selectedSystemGroup = { value: 'All', label: 'All' };
        this.selectedPipelineGroup = { value: 'All', label: 'All' };
        this.selectedDistributionType = { value: 'All', label: 'All' };
        this.selectedTariffRequestType = { value: 'All', label: 'All' };
        this.selectedDestination = { value: 'All', label: 'All' };
        this.selectedUnit = { value: 'All', label: 'All' };
        this.selectedFacilityCode = { value: 'All', label: 'All' };
        this.selectedCurrency = { value: 'All', label: 'All' };
        this.selectedRegulator = { value: 'All', label: 'All' };
        this.selectedLSD = { value: 'All', label: 'All' };
        this.selectedTariffApplication = { value: 'All', label: 'All' };
        this.selectedProductType = { value: 'All', label: 'All' };
        this.selectedProduct = { value: 'All', label: 'All' };
        this.selectedExternalSystem = { value: 'All', label: 'All' };
        this.enteredOperator = { value: 'All', label: 'All' };
        this.selectedTariffStatus = { value: 'All', label: 'All' };
    }

    @Loader
    @Catch(() => errorHandler(PMCDashboardMessages.FETCH_ERROR_MESSAGE))
    async onClearSearch(): Promise<void> {
        const reqBody = [];
        const response = await this.pmcDashboardDataService.getPMCDashboardDetails(reqBody);
          const sortedArrRes =  _.orderBy(response,[item =>item.tariffNumber],['asc']);
        runInAction(() => {
            this.showLoader = false;
            this.resetSearchFilter();
            this.pmcDashboardData = sortedArrRes;
            this.isRefreshed = false;
        })
        this.agGridService.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    @action
    @Loader
    @Catch(() => errorHandler(PMCDashboardMessages.FETCH_ERROR_MESSAGE))
    async onClickEditTariff(tariffData): Promise<void> {
        const assetGroupID = this.tariffRequestTypeData.find(x => x.name === tariffData.assetGroup)?.assetGroupID;
        const reqBody = [{'propertyName': 'TARIFFNO', 'value': tariffData.tariffNumber.toString() }, {'propertyName': 'TARIFFID', 'value': tariffData.pmcTariffDetailID.toString() }]
        const response = await this.pmcDashboardDataService.getPMCDashboardDetails(reqBody);
        runInAction(() => {
            pmcTariffDataStore.editableTariffData = response;
            pmcTariffDataStore.assetGroupID = assetGroupID;
            pmcTariffDataStore.isTariffSaved = true;
            pmcTariffDataStore.isTariffNumberClicked = true;
            pmcTariffDataStore.setTariffRequestType({ 'value': tariffData.assetGroup, 'label': tariffData.assetGroup});
            
            pmcTariffDataStore.pmcTariffNumber = response[0].tariffNumber;
            pmcTariffDataStore.tariffStatus = response[0].pmcTariffDetailStatus;
            pmcTariffDataStore.pmcTariffDetailID = response[0].pmcTariffDetailID;
            pmcTariffDataStore.pmcTariffFeeDetailID = response[0].pmcTariffFeeDetailID;
            pmcTariffDataStore.selectedSystemGroupID = this.systemGroupData.find(x => x.valueText === response[0].pmcSystemGroup)?.settingID;
            pmcTariffDataStore.setSystemGroup({'value': response[0].pmcSystemGroup, 'label': response[0].pmcSystemGroup});
            pmcTariffDataStore.setAgreement(response[0].agreement);
            // pmcTariffDataStore.selectedAgreementEndDate = response[0].agreementEndDate ? new Date(moment(response[0].agreementEndDate).format('YYYY-MM-DD')) : '';
            pmcTariffDataStore.selectedAgreementEndDate = response[0].agreementEndDate ? new Date(response[0].agreementEndDate) : '';
            pmcTariffDataStore.setBSW(parseFloat(response[0].bS_WPenalty));
            const currencyValue = this.currencyData.find(x=>x.settingID===response[0].currency)?.valueText;
            pmcTariffDataStore.setCurrency({ 'value': response[0].currency, 'label': currencyValue});
            const regulatorValue = this.regulatorData.find(x=>x.settingID===response[0].regulator)?.valueText;
            pmcTariffDataStore.setRegulator({ 'value': response[0].regulator, 'label': regulatorValue});
            pmcTariffDataStore.setDeliveryTerminallingFee(parseFloat(response[0].deliveryTerminalling));
            pmcTariffDataStore.setDestination({ 'value': response[0].destination, 'label': response[0].destination});
            pmcTariffDataStore.setDistributionType({ 'value': response[0].distribution, 'label': response[0].distribution});
            pmcTariffDataStore.selectedEffectiveDate = response[0].effectiveDate ? new Date(response[0].effectiveDate) : '';
            pmcTariffDataStore.setElectronicReportingFee(parseFloat(response[0].electronicReportingFee));
            pmcTariffDataStore.setEscalationAmount(response[0].escalationAmount);
            pmcTariffDataStore.setDescription(response[0].additionalDesc);
            pmcTariffDataStore.setEscalationMonth({ 'value': response[0].escalationMonth, 'label': this.monthNames.filter(x=>x.value == response[0].escalationMonth)[0]?.label });
            const externalSystems =  response[0].externalSystem.length > 0 ? response[0].externalSystem.split(',') : [];
            pmcTariffDataStore.setExternalSystems(externalSystems);
            pmcTariffDataStore.setFacilityCode({'value': response[0].pmcFacility, 'label': response[0].pmcFacility});
            pmcTariffDataStore.setIntraSystemTransferFee(parseFloat(response[0].intraSystemTransferFee));
            pmcTariffDataStore.setLSD({'value': response[0].lsd, 'label': response[0].lsd});
            const lossAllowanceValue = response[0].lossAllowance || response[0].lossAllowance != '' ? this.lossAllowanceData.find(x=>x.valueText===response[0].lossAllowance)?.settingID : '';
            pmcTariffDataStore.setLossAllowance({'value': lossAllowanceValue, 'label': response[0].lossAllowance});
            pmcTariffDataStore.setLumpSumFees(parseFloat(response[0].lumpSumFees));
            pmcTariffDataStore.setOperator({'value': response[0].operator, 'label': response[0].operator});
            pmcTariffDataStore.setPipelineGroup({'value': response[0].pmcPipelineGroup, 'label': response[0].pmcPipelineGroup});
            pmcTariffDataStore.setPrimaryRate(parseFloat(response[0].primaryPipelineTariff));
            const products =  response[0].product.length > 0 ? response[0].product.split(',') : [];
            pmcTariffDataStore.setProducts(products);
            pmcTariffDataStore.setProductType({'value': response[0].productTypeID, 'label': response[0].productType});
            pmcTariffDataStore.setQualityMgmtFee(parseFloat(response[0].qualityManagementFee));
            pmcTariffDataStore.setSecondaryRate(parseFloat(response[0].secondaryPipelineTariff));
            pmcTariffDataStore.setReceiptTerminalling(parseFloat(response[0].receiptTerminalling));
            pmcTariffDataStore.setLmciAbandonment(parseFloat(response[0].lmciAbandonmentS_C));
            pmcTariffDataStore.setInjectionFee(parseFloat(response[0].offload_InjectionFee));
            pmcTariffDataStore.setSurcharge(parseFloat(response[0].surcharge));
            const shrinkageValue = response[0].shrinkage || response[0].shrinkage != '' ? this.shrinkageData.find(x=>x.valueText===response[0].shrinkage)?.settingID : '';
            pmcTariffDataStore.setShrinkage({'value': shrinkageValue, 'label': response[0].shrinkage});
            pmcTariffDataStore.setTariffApplication({'value': response[0].tariffApplication, 'label': response[0].tariffApplication});
            const unitValue = this.unitData.find(x=>x.settingID===response[0].unit)?.valueText;
            pmcTariffDataStore.setUnit({'value': response[0].unit, 'label': unitValue});
            pmcTariffDataStore.totalFee = Number(response[0].total);
            pmcTariffDataStore.selectedEffectiveStartDate = new Date(response[0].startDate);
            pmcTariffDataStore.selectedEffectiveEndDate = response[0].endDate ? new Date(response[0].endDate) : '';

            pmcTariffDataStore.setPMCTariffAttachmentGridData(response[0].pmcDocs);
            pmcTariffDataStore.backupAttachmentGridData = response[0].pmcDocs;
            pmcTariffDataStore.setPMCTariffNoteGridData(response[0].pmcNotes);
            pmcTariffDataStore.setOfDecimal(parseFloat(response[0].allowedDecimal));
        })
        history.push('/PMCTariff', { from: '/PMCDashboard' });   
    }

    getColDef() {
        return pmcDashboardColDef;
    }

    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccess(RoleBasedActionName.Save);
    };
}