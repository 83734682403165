import { add } from 'lodash';
import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class CommercialAdvisorXRefDataService {
    constructor(private restApi: RestApiService) { }
    
    async getCAXrefDetails(tariffNumber, assetGroupID, systemGroupID, pmcOperator, distributionID, caTariffName, caSystemGroup, caFacilityCode, caTariffTypeID, caGradeName, caVolumeType): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCCommercialAdvisorXRef+`?tariffNumber=${tariffNumber}&assetGroupID=${assetGroupID}&pipelineSystemGroupID=${systemGroupID}&pmcOperator=${pmcOperator}&distributionID=${distributionID}&caTariffName=${caTariffName}&caSystemGroup=${caSystemGroup}&caFacilityCode=${caFacilityCode}&caTariffTypeID=${caTariffTypeID}&caGradeName=${caGradeName}&caVolumeType=${caVolumeType}`);
        return data;
    } 

    async getCAXrefDetailsWithoutFilter(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCCommercialAdvisorXRef, {});
        return data;
    }
    
    async savePMCCommercialAdvisorXRef(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.savePMCCommercialAdvisorXref, reqbody, {});
        return data;
    }

    async getPMCCommercialAdvisiorXrefMiscData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCCommercialAdvisiorXrefMisc, {});
        return data;
    }
    
    async removePMCCommercialAdvisorXRef(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.removePMCCommercialAdvisorXref, reqbody, {});
        return data;
    }

    async getPMCCommercialAdvisorXRefSupplementaryData(tariffNumber): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCRightAngleXrefSupplementaryData + `?tariffNumber=${tariffNumber}`);
        return data;
    } 

    async getPMCTariffDetailIDs(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCTariffDetailIDs);
        return data;
    }

    async getSystemGroup(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id25);
        return data;
    }

    async getDistributionTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id28);
        return data;
    }

    async getOperators(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getOperators);
        return data;
    }

    async getTariffRequestTypes(jurisdiction): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getTariffRequestTypes + `?jurisdiction=${jurisdiction}`);
        return data;
    } 
    
    async getTariffRateTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCFeeTypes);
        return data;
    }

    async getSettingNameOptions(defaultUserLocation): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id3 + defaultUserLocation);
        return data;
    }

    async getCATariffTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCCATariffType);
        return data;
    }

    async getPMCFacilityCodes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getFacilityCodes);
        return data;
    }

    async getCAVolumeTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCCAVolumeType);
        return data;
    }

    async getCASystemGroups(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCCASystemGroup);
        return data;
    }

    async getCAGradeNames(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCCAGradeName);
        return data;
    }

}

export const commercialAdvisorXRefDataService = new CommercialAdvisorXRefDataService(restApiService);
