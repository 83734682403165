import { ColDef } from 'ag-grid-community';
import { TariffDetailIDCellRenderer } from './cell-renderer/tariff-detail-id';

export const commercialAdvisorXRefColDef: ColDef[] = [
    {   
        headerName: 'Tariff Number',
        field: 'tariffNumber', 
        colId:'tariffNumber',
        cellRenderer: TariffDetailIDCellRenderer, 
        minWidth: 130,
        filter: true,
        sortable: true,
        flex: 1,
    },
    {   
        headerName: 'Asset Group',
        field: 'assetGroup', 
        colId:'assetGroup',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 140,
        flex: 1,
    },
    {   
        headerName: 'Pipeline System',
        field: 'pipelineSystem', 
        colId:'pipelineSystem',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 260,
        flex: 1,
    },
    {   
        headerName: 'Operator',
        field: 'operator', 
        colId:'operator',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 200,
        flex: 1,
    },
    {   
        headerName: 'Distribution',
        field: 'distribution', 
        colId:'distribution',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        flex: 1,
    },
    {   
        headerName: 'Tariff Rate Type',
        field: 'tariffRateType', 
        colId:'tariffRateType',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 150,
        flex: 1,
    },
    {   
        headerName: 'CA Tariff Name',
        field: 'caTariffName', 
        colId:'caTariffName',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 200,
        flex: 1,
    },
    {
        headerName: 'CA System Group',
        field: 'caSystemGroup',
        colId:'caSystemGroup',
        filter: true,
        sortable: true,
        editable: false,
        minWidth: 200,
        flex: 1,
    },
    {
        headerName: 'CA Facility Code',
        field: 'caFacilityCode',
        colId:'caFacilityCode',
        filter: true,
        sortable: true,
        editable: false,
        minWidth: 200,
        flex: 1,
    },
    {
        headerName: 'CA Tariff Type',
        field: 'caTariffTypeName',
        colId:'caTariffTypeName',
        filter: true,
        sortable: true,
        editable: false,
        minWidth: 200,
        flex: 1,
    },
    {
        headerName: 'CA Grade Name',
        field: 'caGradeName',
        colId:'caGradeName',
        filter: true,
        sortable: true,
        editable: false,
        minWidth: 200,
        flex: 1,
    },
    {
        headerName: 'CA Volume Type',
        field: 'caVolumeType',
        colId:'caVolumeType',
        filter: true,
        sortable: true,
        editable: false,
        minWidth: 200,
        flex: 1,
    }
];

