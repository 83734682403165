import { accountStore } from 'features/account/stores';

export const PathDataTypeMap: any = [
    { path: '/Miscellaneous', dataTypeValue: 'MiscellaneousSettingsContainer' },
    { path: '/Agency', dataTypeValue: 'AgencyMaintenanceContainer' },
    { path: '/PMCTariff', dataTypeValue: 'PMCTariffContainer' },
    { path: '/SystemFeeMaintenance', dataTypeValue: 'SystemFeeMaintenanceContainer' },
    { path: '/PMCGridView', dataTypeValue: 'PMCGridViewContainer' },
    { path: '/BaseTariff', dataTypeValue: 'BaseTariffContainer' },
    { path: '/TariffLog', dataTypeValue: 'TariffLogContainer' },
    { path: '/PipelineMaintenance', dataTypeValue: 'PipelineMaintenanceContainer' },
    { path: '/AddPipeline', dataTypeValue: 'AddPipelineMaintenanceContainer' },
    { path: '/EditPipeline', dataTypeValue: 'EditPipelineMaintenanceContainer' },
    { path: '/LocationMaintenance', dataTypeValue: 'LocationMaintenanceContainer' },
    { path: '/TariffRateTypes', dataTypeValue: 'TariffRateTypesContainer' },
    { path: '/ConnectionPointMaintenance', dataTypeValue: 'ConnectionPointMaintenanceContainer' },
    { path: '/ConnectionPointDetails', dataTypeValue: 'AddConnectionPointDetailsContainer'}, 
    { path: '/TariffRateTypes', dataTypeValue: 'TariffRateTypesContainer' },   
    { path: '/ProjectRequest', dataTypeValue: 'ProjectRequestContainer' },    
    { path: '/LocationDetails', dataTypeValue: 'LocationDetailsContainer'},
    { path: '/project-request-step-1', dataTypeValue: 'ProjectRequestStep1Container'},
    { path: '/project-request-step-2', dataTypeValue: 'ProjectRequestStep2Container'},
    { path: '/project-request-infrastructure', dataTypeValue: 'ProjectRequestStep2InfraContainer'},
    { path: '/project-request-clone-tariff-options', dataTypeValue: 'ProjectRequestStep2CloneTariffContainer'},
    { path: '/project-request-vmacs-test', dataTypeValue: 'ProjectRequestStep2VMACSTestContainer'},
    { path: '/project-request-confirm-test', dataTypeValue: 'ProjectRequestStep2VMACSConfirmContainer'},
    { path: '/project-request-tariff-review', dataTypeValue: 'ProjectRequestStep2TariffReviewContainer'},
    { path: '/GradeMaintenance', dataTypeValue: 'GradeMaintenanceContainer' },
    { path: '/FilingEntityMaintenance', dataTypeValue: 'FilingEntityMaintenanceContainer' },
    { path: '/FilingEntityDetails', dataTypeValue: 'AddEditFilingEntityMaintenance' },
    { path: '/FilingCarrierMaintenance', dataTypeValue: 'FilingCarrierMaintenanceContainer' },
    { path: '/FilingCarrierDetails', dataTypeValue: 'AddEditFilingCarrierMaintenance' },
    { path: '/GradeDetails', dataTypeValue: 'AddEditGradeMaintenance' },
    { path: '/LineSegmentMaintenance', dataTypeValue: 'LineSegmentMaintenanceContainer' },
    { path: '/AddEditLineSegmentMaintenance', dataTypeValue: 'AddEditLineSegmentMaintenance' },
    { path: '/ProfitCenterMaintenance', dataTypeValue: 'ProfitCenterMaintenanceContainer' },
    { path: '/ProfitCenterDetails', dataTypeValue: 'AddProfitCenterDetailsContainer' },
    { path: '/ShipperLossGainMaintenance', dataTypeValue: 'ShipperLossGainMaintenanceContainer' },
    { path: '/TariffOptions', dataTypeValue: 'TariffOptionsContainer' },
    { path: '/RightAngleXRef', dataTypeValue: 'RightAngleXRefContainer' },
    { path: '/CommercialAdvisorXRef', dataTypeValue: 'CommercialAdvisorXRefContainer' },
    { path: '/TariffOptions-Index', dataTypeValue: 'TariffOptionsIndexContainer' },
    { path: '/IndexRateMaintenance', dataTypeValue: 'IndexRateMaintenanceContainer' },
    // { path: '/', dataTypeValue:accountStore && accountStore.defaultUserLocation === 'CAN'?  'PMCDashboardContainer' : 'DefaultPageComponent' },
    { path: '/Dashboard', dataTypeValue:'DefaultPageComponent' },
    { path: '/PMCDashboard', dataTypeValue:'PMCDashboardContainer'},
    { path: '/ProjectDashboard', dataTypeValue: 'ProjectDashboardContainer' }, 
    { path: '/TariffDashboard', dataTypeValue: 'TariffDashboardContainer'},
    { path: '/CreateFilingLogEntry', dataTypeValue: 'CreateTariffLogEntryContainer'},
    { path: '/CreateIndexLogEntry', dataTypeValue: 'CreateTariffLogEntryContainer'}  ,
    { path: '/CreateMaintenanceLogEntry', dataTypeValue: 'CreateTariffLogEntryContainer'}  ,
    { path: '/CreateRequestLogEntry', dataTypeValue: 'CreateTariffLogEntryContainer'} , 
    { path: '/CustomerMaintenance', dataTypeValue: 'CustomerMaintenanceContainer'},
    { path: '/AddCustomerMaintenance', dataTypeValue: 'AddCustomerMaintenanceContainer'},
    { path: '/process-tariff-options', dataTypeValue: 'ProcessOptionStep2Container'},
    { path: '/process-clone-tariff-options', dataTypeValue: 'ProcessOptionStep2CloneTariffContainer'},
    { path: '/process-tariff-infrastructure', dataTypeValue: 'ProcessOptionStep2InfrastructureTestContainer'},
    { path: '/process-tariff-review', dataTypeValue: 'ProcessOptionStep2TariffReviewContainer'},
    { path: '/process-tariff-approval', dataTypeValue: 'ProcessOptionStep2TariffApprovalContainer'},
];

