import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { CommercialAdvisorXRefDataStore } from '../stores/commercial-advisor-xref-data-store';
import {
    GridOptions,
} from 'ag-grid-community';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import history from '../../../../shared/components/header-component/component/history';
import './commercial-advisor-xref.scss';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { commercialAdvisorXRefDataStore } from '../stores';
import { ReactSelect } from 'common/components/select/select';
import { AddEditCAXRef } from './AddEditCAXref';

/* eslint-disable */
interface CommercialAdvisorXRefContainerProps {
    commercialAdvisorXRefDataStore: CommercialAdvisorXRefDataStore;
}

interface CommercialAdvisorXRefContainerState {
    search: string;
}

@inject('commercialAdvisorXRefDataStore')
@observer
export class CommercialAdvisorXRefContainer extends Component<CommercialAdvisorXRefContainerProps, CommercialAdvisorXRefContainerState> {
    constructor(props: CommercialAdvisorXRefContainerProps | Readonly<CommercialAdvisorXRefContainerProps>) {
        super(props);
        this.state = {
            search: ''
        };
        dataStore.setModuleName('RA XRef')
    }
    
    componentDidMount() {
        history.push('/CommercialAdvisorXRef');
        const { commercialAdvisorXRefDataStore } = this.props;
        commercialAdvisorXRefDataStore.init();
        commercialAdvisorXRefDataStore.agGridService.refreshGrid();
    }

    onSelectTariffNumber = (e) => {
        commercialAdvisorXRefDataStore.setTariffNumber(e);
    };

    onChangeOperator = (e) => {
        commercialAdvisorXRefDataStore.setOperator(e);
    };
    
    onChangeRAName = (e) => {
        commercialAdvisorXRefDataStore.setRAName(e);
    };

    onSelectRAName = (e) => {
        commercialAdvisorXRefDataStore.setRAName(e);
    };

    onSelectSystemGroup = (e) => {
        commercialAdvisorXRefDataStore.setSystemGroup(e);
    };

    onSelectDistributionType = (e) => {
        commercialAdvisorXRefDataStore.setDistributionType(e);
    };

    onSelectTariffRequestType = (e) => {
        commercialAdvisorXRefDataStore.setTariffRequestType(e);
    };

    onSelectTariffRateType = (e) => {
        commercialAdvisorXRefDataStore.setTariffRateType(e);
    };

    onSelectCaTariffName = (e) => {
        commercialAdvisorXRefDataStore.setCaTariffName(e);
    }

    onSelectCaSystemGroup = (e) => {
        commercialAdvisorXRefDataStore.setCaSystemGroup(e);
    }

    onSelectCaFacilityCode = (e) => {
        commercialAdvisorXRefDataStore.setCaFacilityCode(e);
    }

    onSelectCaTypeID = (e) => {
        commercialAdvisorXRefDataStore.setCaTariffType(e);
    }

    onSelectCaGradeName = (e) => {
        commercialAdvisorXRefDataStore.setCaGradeName(e);
    }

    onSelectCaVolumeType = (e) => {
        commercialAdvisorXRefDataStore.setCaVolumeType(e);
    }

    onAddNewClick = async() => {
      commercialAdvisorXRefDataStore.onAddNewCAXRef();
    }
    
    render(): React.ReactNode {
      const { commercialAdvisorXRefDataStore } = this.props;
      return (
        <Container fluid>
          {this.renderRightAngleXRefScreen()}
        </Container>
      );
    }

    renderRightAngleXRefScreen() : React.ReactNode {
      const { commercialAdvisorXRefDataStore } = this.props;
      return (
        <div className='rightAngleXRefBox'>
            <Container fluid>
                <div>
                    <div className='screenHeader'>
                    <h2 className='screen_title'>Commercial Advisor XRef - Mapping</h2>
                    </div>
                    <div className='selectContainer'>
                    <div className='settingSelectionDiv'>
                    <Row>
                        <Col>
                            <label className='standard_label_style'>Tariff Number</label>
                            <ReactSelect
                                className='select'
                                values={commercialAdvisorXRefDataStore.selectedTariffNumber}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...commercialAdvisorXRefDataStore.tariffNumberDropdownOptions]}
                                onChange={this.onSelectTariffNumber}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        <Col>
                            <label className='standard_label_style'>Operator</label>
                            <ReactSelect
                                className='select'
                                values={commercialAdvisorXRefDataStore.selectedOperator}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...commercialAdvisorXRefDataStore.operatorDropdownOptions]}
                                onChange={this.onChangeOperator}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        <Col>
                                <label className="standard_label_style">CA Tariff Name</label>
                                <ReactSelect
                                    className='select'
                                    values={commercialAdvisorXRefDataStore.selectedCaTariffName}
                                    placeholder="Select"
                                    options={[{value:'All', label:'All'},...commercialAdvisorXRefDataStore.caTariffNameDropdownOptions]}
                                    onChange={this.onSelectCaTariffName}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                            </Col>
                            <Col>
                                
                                <label className="standard_label_style">CA Tariff Type</label>
                                <ReactSelect
                                    className='select'
                                    values={commercialAdvisorXRefDataStore.selectedCaTariffType}
                                    placeholder="Select"
                                    options={[{value:'All', label:'All'},...commercialAdvisorXRefDataStore.caTariffTypeDropdownOptions]}
                                    onChange={this.onSelectCaTypeID}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                            </Col>
                    </Row>
                        <Row>
                            <Col>
                                <label className='standard_label_style'>Asset Group</label>
                                <ReactSelect
                                    className='select'
                                    values={commercialAdvisorXRefDataStore.selectedTariffRequestType}
                                    placeholder="Select"
                                    options={[{value:'All', label:'All'},...commercialAdvisorXRefDataStore.tariffRequestTypeDropdownOptions]}
                                    onChange={this.onSelectTariffRequestType}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                            </Col>
                            <Col>
                                <label className='standard_label_style'>Distribution</label>
                                <ReactSelect
                                    className='select'
                                    values={commercialAdvisorXRefDataStore.selectedDistributionType}
                                    placeholder="Select"
                                    options={[{value:'All', label:'All'},...commercialAdvisorXRefDataStore.distributionTypeDropdownOptions]}
                                    onChange={this.onSelectDistributionType}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                            </Col>
                            <Col>
                                <label className="standard_label_style">CA System Group</label>
                                <ReactSelect
                                    className='select'
                                    values={commercialAdvisorXRefDataStore.selectedCaSystemGroup}
                                    placeholder="Select"
                                    options={[{value:'All', label:'All'},...commercialAdvisorXRefDataStore.caSystemGroupDropdownOptions]}
                                    onChange={this.onSelectCaSystemGroup}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                            </Col>
                            <Col>
                                <label className="standard_label_style">CA Grade Name</label>
                                <ReactSelect
                                    className='select'
                                    values={commercialAdvisorXRefDataStore.selectedCaGradeName}
                                    placeholder="Select"
                                    options={[{value:'All', label:'All'},...commercialAdvisorXRefDataStore.caGradeNameDropdownOptions]}
                                    onChange={this.onSelectCaGradeName}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label className="standard_label_style">Pipeline System</label>
                                <ReactSelect
                                    className='select'
                                    values={commercialAdvisorXRefDataStore.selectedSystemGroup}
                                    placeholder="Select"
                                    options={[{value:'All', label:'All'},...commercialAdvisorXRefDataStore.systemGroupDropdownOptions]}
                                    onChange={this.onSelectSystemGroup}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                            </Col>
                            <Col>
                                <label className="standard_label_style">Tariff Rate Type</label>
                                <ReactSelect
                                    className='select'
                                    values={commercialAdvisorXRefDataStore.selectedTariffRateType}
                                    placeholder="Select"
                                    options={[{value:'All', label:'All'},...commercialAdvisorXRefDataStore.tariffRateTypeDropdownOptions]}
                                    onChange={this.onSelectTariffRateType}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                            </Col>
                            <Col>
                                <label className="standard_label_style">CA Facility Code</label>
                                <ReactSelect
                                    className='select'
                                    values={commercialAdvisorXRefDataStore.selectedCaFacilityCode}
                                    placeholder="Select"
                                    options={[{value:'All', label:'All'},...commercialAdvisorXRefDataStore.caFacilityCodeDropdownOptions]}
                                    onChange={this.onSelectCaFacilityCode}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                            </Col>
                            <Col>
                                <label className="standard_label_style">CA Volume Type</label>
                                <ReactSelect
                                    className='select'
                                    values={commercialAdvisorXRefDataStore.selectedCaVolumeType}
                                    placeholder="Select"
                                    options={[{value:'All', label:'All'},...commercialAdvisorXRefDataStore.caVolumeTypeDropdownOptions]}
                                    onChange={this.onSelectCaVolumeType}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className='searchButtonDiv'>
                        <CustomButton type={CustomButtonType.Submit} onClick={()=> commercialAdvisorXRefDataStore.onSearchClick()}>
                            Search
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={()=> commercialAdvisorXRefDataStore.onClearSearch()}>
                            Clear
                        </CustomButton>
                        <div className='otherHeaderButtonsDiv'>
                            <CustomButton type={CustomButtonType.Submit} onClick={()=> this.onAddNewClick()} > 
                                Add New CA Xref
                            </CustomButton>
                        </div>
                    </div>
                    </div>
                {this.props.commercialAdvisorXRefDataStore.showLoader == true &&
                    <div className = 'alert-overlay'> 
                    <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                    </div> 
                }
                <AgGridComponent gridConfig={this.getGridConfig('CA XRef')} />
                </div>
              </Container >
              {commercialAdvisorXRefDataStore.isShowModal && <AddEditCAXRef commercialAdvisorXRefDataStore={commercialAdvisorXRefDataStore}  />
          }
        </div>
      );
    }

    private getGridConfig(gridId): GridOptions {
      const {
          commercialAdvisorXRefDataStore: { commercialAdvisorXRefData, agGridService }
      } = this.props;
      const { commercialAdvisorXRefDataStore } = this.props;
      const columnDefs = commercialAdvisorXRefDataStore.getColDef();
      const rowData = commercialAdvisorXRefData;
      const pmcDashboardGridParams: any = {
        rowData,
        columnDefs
      };
      return agGridService.getGridConfig(pmcDashboardGridParams, gridId);
    }
}