import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class ProcessOptionDataService {
    constructor(private restApi: RestApiService) {}

    // async getRateTypesGridData(fercTypeId, rateTypeId): Promise<any[]> {
    //     const { data } = await this.restApi.get<any>(Path.rateTypeGrid+`?fercTypeID=${fercTypeId}&rateTypeID=${rateTypeId}`);
    //     return data;
    // }
    
    async getProjectTypesDropDownData(defaultLocation): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getWorkflowDetails+`?jurisdiction=${defaultLocation}`);
        return data;
    }

    async getPMCTariffRateTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id28);
        return data;
    }

    async getWorkflowNotesByWFID(WorkFlowID): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getWorkflowNotesByWFID+`?workflowId=${WorkFlowID}`);
        return data;
    }

    async getWorkflowDocumentByWFID(WorkFlowID): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getWorkflowDocumentByWFID+`?workflowId=${WorkFlowID}`);
        return data;
    }
    
    async step0PostWorkflowDetails(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.step0_postWorkflowDetails, reqbody, {});
        return data;
    }    

    async getPipelines(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.step_1_getPipelines);
        return data;
    }

    
    async getBaseTariffs(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.step_1_getBaseTariffs);
        return data;
    }
    
    async getWorkflowDetails(workflowId): Promise<any> {
        const { data } = await this.restApi.get<any>(Path.step_1_getWorkflowDetails+`?workflowId=${workflowId}`);
        return data;
    }

    
    async getWorkflowStatusGridData(workflowId): Promise<any> {
        const { data } = await this.restApi.get<any>(Path.step_1_getWorkflowStatusGridDetails+`?workflowId=${workflowId}`);
        return data;
    }

    
    async getWorkflowHistoryStatusGridData(workflowId): Promise<any> {
        const { data } = await this.restApi.get<any>(Path.step_1_getWorkflowHistoryStatusGridDetails+`?workflowId=${workflowId}`);
        return data;
    }

    async getSystemGroup(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id25);
        return data;
    }

    async getPMCPipelineSystems(assetGroupID): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCPipelineSystems + `?assetGroupID=${assetGroupID}`);
        return data;
    }

    async getPipelineGroup(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id24);
        return data;
    }

    async getLocations(pipelineId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getLocations+`?baseSystemId=${pipelineId}&locationName=All&status=All`);
        return data;
    }

    async getCP(locationId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getConnectionPointData+`?locationID=${locationId}`);
        return data;
    }

    async getAgencies(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.tariffOptions_GetAgencyDropdown);
        return data;
    }

    async getProfitCenter(pipelineId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getProfitCenterData+`?baseID=${pipelineId}`);
        return data;
    }
    
    async getGM(pipelineId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getGrades+`?grade=All&gradeName=All&pipelineSystemId=${pipelineId}`);
        return data;
    }

    async getCustomerMaintenance(pipelineId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.tariffOptions_ownerDropdown+`?pipelineId=${pipelineId}`);
        return data;
    }
    
    async getLineSegments(pipelineId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getLineSegmentData+`?plAreaID=${pipelineId}`);
        return data;
    }

    async getLinkedWorkflowData(workflowId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.step_2_getLinkedWorkflow+`?workflowID=${workflowId}`);
        return data;
    }

    
    async getLinkedBaseAndLog(workflowId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.step_2_getLinkedBaseAndLog+`?workflowID=${workflowId}`);
        return data;
    }


    async getOptionInfo(workflowId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.step_2_getOptionInfo+`?workflowID=${workflowId}`);
        return data;
    }
       
    async getSelectedBaseTariffDetails(id, process, logId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.url_id16}?baseID=${id}&process=${process}&logId=${logId}`);
        return data;
    }
    
    

    async step2UnlinkWorkflow(workflowId, username, reqbody: any [], linkerType='BASE'): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.step_2_unlinkWorkflow+`?WorkFlowID=${workflowId}&LinkerType=${linkerType}&User=${username}`, reqbody, {});
        return data;
    }

    async processTariffOptions(logId, username, workflowId): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.step_2_processWorkflowLog+`?user=${username}&workflowID=${workflowId}`, {}, {});
        return data;
    }

    async getPMCTariffDetail(assetGroupID, systemGroupId, pipelineId, distributionId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.GetPMCTariffSetup+`?AssetID=${assetGroupID}&PLSystemID=${systemGroupId}&PLRegionID=${pipelineId}&DistID=${distributionId}`);
        return data;
    }

    async step1SavePostWorkflowDetails(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.step_1_saveWorkflowDetails, reqbody, {});
        return data;
    }

    async step1SaveComments(workflowId, comments, username,workflowStepFlowId ): Promise<any[]> {
        const reqBody = { 'workflowID': workflowId, 'comment': comments, 'user': username, 'workflowStepFlowID': workflowStepFlowId}
        const { data } = await this.restApi.post<any>(Path.step_1_saveComments, reqBody, {});
        return data;
    }

    async step1SendEmail(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.step_1_sendEmail, reqbody, {});
        return data;
    }
    
    async step1SendEmailWorkflow(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.step_1_sendEmailWorkflow, reqbody, {});
        return data;
    }
    
    async step2aGetTariffLog(tarifBaseId): Promise<any> {
        const { data } = await this.restApi.get<any>(Path.step_2_getTariffLog+`?TariffBaseID=${tarifBaseId}`);
        return data;
    }

    async goToPreviousPage(workflowId, username,workflowStepFlowId ): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.step_2_goToPrevious+`?workflowId=${workflowId}&user=${username}&workflowstepflowId=${workflowStepFlowId}`, {}, {});
        return data;
    }

    async step2aLinkWorkflow(workflowId, username, reqbody: any [], linkerType='BASE'): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.step_2_linkWorkflow+`?WorkFlowID=${workflowId}&LinkerType=${linkerType}&User=${username}`, reqbody, {});
        return data;
    }
    
    async savePMCRightAngleTariffStaging(workflowId, reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.savePMCRightAngleTariffStaging+`?workflowId=${workflowId}`, reqbody, {});
        return data;
    }

    async savePMCCommercialAdvisorTariffStaging(workflowId, user, reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.savePMCCommercialAdvisorTariffStaging+`?workflowId=${workflowId}`+`&user=${user}`, reqbody, {});
        return data;
    }


    async savePMCWorkflowSelectedTariffDetails(workflowId, reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.savePMCWorkflowSelectedTariffDetails+`?workflowId=${workflowId}`, reqbody, {});
        return data;
    }

    async getPMCWorkflowSelectedTariffDetails(workflowId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCWorkflowSelectedTariffDetails+`?workflowID=${workflowId}`);
        return data;
    }

    async saveWFTariffNotes(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.saveWFTariffNotes, reqbody, {});
        return data;
    }

    async deleteWFTariffNote(notesID: number): Promise<any[]> {
        const { data } = await this.restApi.delete<any>(Path.deleteWFTariffNote+`?notesID=${notesID}`);
        return data;
    }

    async uploadFile(formData): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.saveWFTariffDocument, formData);
        return data;
    }

    async updateAttachment(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.updateWFAttachment, reqbody, {});
        return data;
    }

    async downloadAttachment(fileID): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.downloadWFAttachment+`?docId=${fileID}`);
        return data;
    }

    async deleteAttachment(docID): Promise<any[]> {
        const { data } = await this.restApi.delete<any>(Path.deleteWFAttachment+`?docId=${docID}`);
        return data;
    }

    async getWFReviewTariffDetail(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.getWFReviewTariffDetail, reqbody, {});
        return data;
    }

    async getPMCDashboardDetails(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.getPMCDashboardDetails, reqbody, {});
        return data;
    }

    async getAssetGroups(jurisdiction): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getTariffRequestTypes + `?jurisdiction=${jurisdiction}`);
        return data;
    }

    async getPMCShrinkage(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCShrinkage);
        return data;
    }

    async getPMCLossAllwance(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCLossAllwance);
        return data;
    }

    async getUnits(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id29);
        return data;
    }

    async getCurrency(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id30);
        return data;
    }

    async getRAXrefDetailsWithoutFilter(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCRightAngleXref, {});
        return data;
    }

    async getCAXrefDetailsWithoutFilter(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCCommercialAdvisorXRef, {});
        return data;
    }

    async getParentStatus(workflowID): Promise<any> {
        const { data } = await this.restApi.get<any>(`${Path.getParentStatus}?workflowID=${workflowID}`, {});
        return data;
    }    
}

export const processOptionDataService = new ProcessOptionDataService(restApiService);
